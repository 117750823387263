/**
 * Utility function to transform data structure.
 * @param {Object} input - Input data containing userData and contactData arrays.
 * @returns {Array} - Formatted data as an array.
 */
function formatMapsData(input = {}) {
  const formattedData = [];

  // Process userData
  if (input?.userData) {
    input.userData.forEach((user) => {
      formattedData.push({
        _id: user._id,
        location: user.location,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
      });
    });
  }

  // Process contactData
  if (input?.contactData) {
    input.contactData.forEach((contact) => {
      formattedData.push({
        _id: contact.contactUserId,
        location: contact.userLocation,
        firstName: contact.contactInfo.name.split(' ')[0] || null,
        lastName: contact.contactInfo.name.split(' ')[1] || null,
        role: 'individual',
      });
    });
  }

  //
  if (input?.branchesLocation) {
    input.branchesLocation.forEach((contact) => {
      formattedData.push({
        _id: contact._id,
        location: contact.location,
        firstName: contact.firstName || null,
        lastName: contact.lastName || null,
        // role: 'individual',
      });
    });
  }
  return formattedData;
}

function formatFeaturesDataIntoOptions(data = []) {
  return data.map((item) => ({
    label: item?.name?.charAt(0)?.toUpperCase() + item?.name?.slice(1),
    value: item?._id,
  }));
}

/**
 * Utility function to capitalize the first letter of a string.
 * @param {String} val - Input string.
 * @returns {String} - Capitalized string.
 */
function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

import { BiCar, BiCard, BiShoppingBag } from 'react-icons/bi';
import { BsPersonBadge, BsPersonCheck, BsPostcard } from 'react-icons/bs';
import { CiVault } from 'react-icons/ci';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { LuQrCode } from 'react-icons/lu';

const childRoutes = {
  card: {
    business: [
      { to: '/cards', text: 'Company Cards' },
      { to: '/cards/employees', text: 'Employee Cards' },
    ],
    individual: [{ to: '/cards', text: 'My Cards' }],
  },
};

// Update child routes for "cards" in `getChildren`
// Update childRoutes to include Employee Cards for cards in all cases

// Modify getChildren to dynamically include Employee Cards if "employee" exists
function getChildren(featureName, type, allFeatures) {
  const childrenMap = {
    stores: [
      { to: '/stores', text: 'My Store' },
      { to: '/product-categories', text: 'Product Categories' },
      { to: '/products', text: 'Products' },
      { to: '/orders-list', text: 'Orders' },
      { to: '/store-reviews', text: 'Reviews' },
    ],
    cards: (() => {
      const baseCards =
        type === 'business'
          ? childRoutes.card.business
          : childRoutes.card.individual;

      // Include Employee Cards if "employee" feature exists in the feature array
      if (
        allFeatures.some((feature) => feature.featureName === 'employee') &&
        allFeatures.some((feature) => feature.featureName === 'cards')
      ) {
        return [
          { to: '/cards', text: 'Company Cards' },
          { to: '/cards/employees', text: 'Employee Cards' },
        ];
      } else if (
        allFeatures.some((feature) => feature.featureName === 'cards')
      ) {
        return [{ to: '/cards', text: 'Company Cards' }];
      } else if (
        allFeatures.some((feature) => feature.featureName === 'employee')
      ) {
        return [{ to: '/cards/employees', text: 'Employee Cards' }];
      }

      return baseCards;
    })(),

    contacts: [
      {
        to: '/friends/categories',
        text: 'Friends Categories',
      },
      {
        to: '/friends',
        text: 'Friends',
      },
    ],
    QRCode: [
      { to: '/wifi-qr', text: 'WiFi QR' },
      { to: '/qr-codes', text: 'QR Codes' },
    ],
    cars: [{ to: '/all/cars', text: 'All Cars' }],
  };

  return childrenMap[featureName] || [];
}

// Adjust `convertPlanFeatures` to ensure "employee" isn't a parent route
function convertPlanFeatures(plans = [], type) {
  const seenFeatures = new Set();
  // add cards if plan have employee key

  const featureOrder = [
    'cards',
    'qrcode',
    'posts',
    'stores',
    'cars',
    'contacts',
    'cv',
  ]; // Define priority order

  return plans
    .map((plan) => {
      const hasEmployeeFeature = plan.features.some(
        (feature) => feature.featureName === 'employee'
      );

      return plan.features
        .filter((feature) => {
          // Exclude "employee" from the parent routes
          if (feature.featureName === 'employee') return false;
          if (seenFeatures.has(feature.featureName)) return false;
          seenFeatures.add(feature.featureName);
          return true;
        })
        .map((feature) => {
          const children = getChildren(
            feature.featureName,
            type,
            plan.features
          );

          return {
            to:
              children?.length > 0
                ? '#'
                : `/${feature.featureName.toLowerCase()}`,
            icon: getIcon(feature.featureName),
            text: capitalize(feature.featureName),
            condition: feature.featureName.toLowerCase(),
            ...(children.length > 0 && { children }),
          };
        })
        .concat(
          // Add "cards" as a parent route if "employee" exists
          hasEmployeeFeature && !seenFeatures.has('cards')
            ? [
                {
                  to: '#',
                  icon: getIcon('cards'),
                  text: 'Cards',
                  condition: 'cards',
                  children: getChildren('cards', type, plan.features),
                },
              ]
            : []
        );
    })

    .flat()
    .sort(
      (a, b) =>
        featureOrder.indexOf(a.condition) - featureOrder.indexOf(b.condition)
    ); // Sort based on priority order
}

// Helper function to map feature names to icons
function getIcon(featureName) {
  const iconMap = {
    employee: <BsPersonBadge size={20} />,
    stores: <BiShoppingBag size={20} />,
    posts: <BsPostcard size={20} />,
    cards: <BiCard size={20} />,
    QRCode: <LuQrCode size={20} />,
    CV: <CiVault size={20} />,
    contacts: <BsPersonCheck size={20} />,
    cars: <BiCar size={20} />,
  };
  return iconMap[featureName] || <FaEnvelopeOpenText size={20} />; // Default icon
}

// Helper function to capitalize feature names
function capitalize(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

/**
 * Converts an array of objects into a formatted country phone options array.
 *
 * @param {Array} data - Array of business objects.
 * @returns {Array} - Formatted array of label-value pairs.
 */
function formatBranchData(data = []) {
  if (!Array.isArray(data)) {
    throw new Error('Input data must be an array.');
  }

  return data
    .filter((item) => item.businessName != null) // Skip objects with null or undefined businessName
    .map((item) => ({
      label: item.businessName,
      value: item._id,
    }));
}

export {
  capitalizeFirstLetter,
  convertPlanFeatures,
  formatBranchData,
  formatFeaturesDataIntoOptions,
  formatMapsData,
};
