import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { FaArrowLeft as ArrowLeft } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import InputField from '../../components/ui/InputField';
import { usePurchasePlanMutation } from '../../redux/features/subscriptions/subscriptionsApi';

const BillingPage = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState();
  const location = useLocation();
  const plan = location?.state?.plan;
  const [purchasePlan, { isLoading, isSuccess, isError }] =
    usePurchasePlanMutation();

  const handlePayNow = async () => {
    const data = {
      ...values,
      subscriptionId: plan?._id,
      cardType: 'Visa',
    };
    const response = await purchasePlan(data);
    if (response?.data?.success) {
      toast.success(response?.data?.message);
      navigate('/subscription/history');
    } else {
      console.log(response);
      toast.error(response?.error?.data?.message);
    }
  };

  const onChange = (key, value, subKey) => {
    if (subKey) {
      setValues((prev) => {
        const v = prev?.[key] || {};
        return {
          ...prev,
          [key]: {
            ...v,
            [subKey]: value,
          },
        };
      });
    } else {
      setValues((prev) => {
        return { ...prev, [key]: value };
      });
    }
  };

  return (
    <Card
      title={
        <div className="flex items-center">
          <div className="z-1  mr-4 flex items-center justify-start">
            <ArrowLeft
              className="h-6 w-6 cursor-pointer text-gray-600"
              onClick={() => navigate(-1)}
            />
          </div>
          <span>Card Detail</span>
        </div>
      }
    >
      <div className="mb-8 rounded-md border p-4 shadow-sm">
        <h5 className="text-[16px] font-medium">Price Summary</h5>
        <div className="mt-4 flex justify-between">
          <span className="text-gray-600">Original Price:</span>
          <span className="font-medium">{plan?.planPrice}</span>
        </div>
        {/* <div className="mt-2 flex justify-between">
          <span className="text-gray-600">Tax:</span>
          <span className="font-medium">{plan?.planPrice}</span>
        </div> */}
        <div className="mt-2 flex justify-between border-t pt-2">
          <span className="font-semibold text-gray-600">Total:</span>
          <span className="font-semibold">{plan?.planPrice}</span>
        </div>
      </div>

      <form onSubmit={handlePayNow}>
        <div className="mb-4 grid grid-cols-2 gap-4">
          <InputField
            icon={<FaUser />}
            name="cardHolderName"
            value={values?.cardHolderName}
            onChange={onChange}
            placeholder="Full Name"
            type="text"
            isRequired={true}
          />

          <InputField
            icon={<FaUser />}
            name="cardNumber"
            value={values?.cardNumber}
            onChange={onChange}
            placeholder="Card Number"
            type="number"
            isRequired={true}
            maxLength={16}
          />

          {/* <InputField
            icon={<FaUser />}
            name="expiryDate"
            value={values?.expiryDate}
            onChange={onChange}
            placeholder="Expiry Date"
            type="text" // Changed from "date" to "text"
            isRequired={true}
            maxLength={7} // Format: MM/YYYY
            // pattern="\d{2}/\d{4}" // Regex to enforce MM/YYYY
            title="Please enter the expiry date in MM/YYYY format"
          /> */}

          <InputField
            icon={<FaUser />}
            name="cvv"
            value={values?.cvv}
            onChange={onChange}
            placeholder="CVV"
            type="number"
            isRequired={true}
            maxLength={3}
          />
          <InputField
            icon={<FaUser />}
            name="address"
            value={values?.address}
            onChange={onChange}
            placeholder="Add billing address"
            type="text"
            isRequired={true}
            // maxLength={3}
          />
          <InputField
            icon={<FaUser />}
            name="expiryDate"
            value={values?.expiryDate}
            onChange={onChange}
            placeholder="Card Expiry Date"
            type="date"
            startDate={new Date()}
            isRequired={true}
            label={'Expiry Date'}
          />
        </div>
        <Button
          type="button"
          onClick={handlePayNow}
          className="w-full rounded-md bg-green-500 px-4 py-2 text-white"
        >
          {isLoading ? 'Processing...' : 'Pay Now'}
        </Button>
      </form>
    </Card>
  );
};

export default BillingPage;
