import React from 'react';
import { AiFillAppstore } from 'react-icons/ai';
import { BsDot } from 'react-icons/bs';
import { FaEnvelopeOpenText } from 'react-icons/fa';
import { FiBox } from 'react-icons/fi';
import { GoHomeFill } from 'react-icons/go';
import { IoSettingsSharp } from 'react-icons/io5';
import { MdLocationOn } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { convertPlanFeatures } from '../../utils/dataFormater';
import SidebarLinkGroup from '../SidebarLinkGroup';

const BusinessSidebar = ({
  pathname,
  setSidebarExpanded,
  sidebarExpanded,
  tinySidebarOpen,
  purchasedFeaturesData,
}) => {
  const navLinkClass = `text-bodydark1 hover:bg-graydark dark:hover:bg-meta-4 group relative flex items-center gap-2.5 rounded-sm px-4 py-2 font-medium duration-300 ease-in-out`;

  // posts, stores, cards, home, stores and store reviews,  products and product categories, settings, cars, interests, wifi qr, addresses,

  const paidFeatures = convertPlanFeatures(purchasedFeaturesData, 'business');
  const unpaidFeaturesOne = [
    {
      to: '/',
      icon: <AiFillAppstore size={20} />,
      text: 'Dashboard',
      condition: 'dashboard',
    },
    {
      to: '/home',
      icon: <GoHomeFill size={20} />,
      text: 'Home',
      condition: 'home',
    },
  ];
  const unpaidFeaturesTwo = [
    {
      to: '/addresses',
      icon: <MdLocationOn size={20} />,
      text: 'Addresses',
      condition: 'addresses',
    },
    {
      to: '/messages',
      icon: <FaEnvelopeOpenText size={20} />,
      text: 'Messages',
      condition: 'messages',
    },
  ];

  const unpaidFeaturesThree = [
    {
      to: '/settings',
      icon: <IoSettingsSharp size={20} />,
      text: 'Settings',
      condition: 'settings',
    },

    {
      to: '/subscription/history',
      icon: <FiBox size={20} />,
      text: 'Subscription',
      condition: 'subscription',
    },
  ];
  const navItems = [
    ...unpaidFeaturesOne,
    ...paidFeatures,
    ...unpaidFeaturesTwo,
    ...unpaidFeaturesThree,
  ];

  return (
    <ul className="mb-6 flex flex-col gap-1.5">
      {/* <DebugVie />  */}
      {navItems.map((item, index) => (
        <li key={index}>
          {item.children ? (
            <SidebarLinkGroup
              activeCondition={pathname.includes(item.condition)}
            >
              {(handleClick, open) => (
                <React.Fragment>
                  <NavLink
                    to={item.to}
                    className={`${navLinkClass} hover:text-white`}
                    onClick={(e) => {
                      e.preventDefault();
                      handleClick(); // Toggle the open state
                      setSidebarExpanded(true);
                    }}
                  >
                    {item.icon}
                    {tinySidebarOpen ? item.text : null}
                    {tinySidebarOpen ? (
                      <svg
                        className={`absolute right-4 top-1/2 -translate-y-1/2 fill-current ${
                          open ? 'rotate-180' : ''
                        }`}
                        width="20"
                        height="20"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M4.41107 6.9107C4.73651 6.58527 5.26414 6.58527 5.58958 6.9107L10.0003 11.3214L14.4111 6.91071C14.7365 6.58527 15.2641 6.58527 15.5896 6.91071C15.915 7.23614 15.915 7.76378 15.5896 8.08922L10.5896 13.0892C10.2641 13.4147 9.73651 13.4147 9.41107 13.0892L4.41107 8.08922C4.08563 7.76378 4.08563 7.23614 4.41107 6.9107Z"
                        />
                      </svg>
                    ) : null}
                  </NavLink>

                  <div
                    className={`translate transition-max-height transform overflow-hidden duration-300 ${
                      !open ? 'max-h-0' : 'max-h-screen'
                    }`}
                  >
                    <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                      {item.children.map((child, childIndex) => (
                        <li key={childIndex}>
                          <NavLink
                            to={child.to}
                            onClick={() => setSidebarExpanded(true)}
                            className={({ isActive }) =>
                              'text-bodydark2 group relative flex items-center gap-2.5 rounded-md px-4 font-medium duration-300 ease-in-out hover:text-white ' +
                              (child?.to === pathname && '!text-white')
                            }
                          >
                            <BsDot />
                            {child.text}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                </React.Fragment>
              )}
            </SidebarLinkGroup>
          ) : (
            <NavLink
              to={item.to}
              className={
                `${navLinkClass} ${
                  pathname === item?.to && ' text-white'
                } hover:text-white` +
                ` ${item?.condition === 'posts' && 'hidden md:flex'}`
              }
            >
              {item.icon}
              {tinySidebarOpen ? (
                <span
                  className={`overflow-hidden  transition-opacity duration-1000`}
                >
                  {item.text}
                </span>
              ) : null}
            </NavLink>
          )}
        </li>
      ))}
    </ul>
  );
};

export default BusinessSidebar;
