import React from 'react';

// Utility component
const DebugView = ({ data }) => {
  if (process.env.NODE_ENV === 'production') {
    return null; // Don't render in production
  }

  return (
    <pre className="mt-4 rounded bg-gray-100 p-4">
      {JSON.stringify(data, null, 2)}
    </pre>
  );
};

export default DebugView;
