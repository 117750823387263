import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import * as XLSX from 'xlsx';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetUsersQuery } from '../../../redux/features/users/usersApi';
import UserActions from './UserActions';
import UserFilters from './UserFilters';

const UsersPage = () => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  const [filtrationData, setFiltrationData] = useState();

  const { data, isLoading } = useGetUsersQuery({
    page: paginate?.page,
    limit: paginate?.limit,
    firstName: filtrationData?.firstName || '',
    lastName: filtrationData?.lastName || '',
    dialCode: filtrationData?.phone?.dialCode || '',
    phone: filtrationData?.phone?.fullNumber || '',
    country: filtrationData?.country || '',
    username: filtrationData?.username || '',
    businessName: filtrationData?.company || '',
    email: filtrationData?.email || '',
    role: filtrationData?.role || '',
  });

  const content = {
    header: [
      'Username',
      'Name',
      'Email',
      'Country',
      'Business Name',
      'Phone',
      'QR Code Limit',
      'Role',
      'Permissions',
      'Actions',
    ],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.username,
        },
        {
          row: `${item?.firstName || ''} ${item?.lastName || ''}`,
        },
        {
          row: item?.email,
        },
        {
          row: item?.location?.country,
        },
        {
          row: item?.businessName,
        },
        {
          row: (
            <span>
              {item?.phone?.dialCode} {item?.phone?.fullNumber}
            </span>
          ),
        },
        {
          row: (
            <UserActions
              isRoleAction={false}
              isQRLimitAction={true}
              isButton={false}
              data={{
                qrLimit: item?.activity?.permission?.qrCode?.limit,
                id: item?._id,
              }}
            />
          ),
        },
        {
          row: (
            <UserActions
              isRoleAction={true}
              isButton={false}
              data={{ role: item?.role, id: item?._id }}
            />
          ),
        },
        {
          row: (
            <Button link={`/admin/user/permissions/${item?._id}`}>View</Button>
          ),
        },
        {
          row: (
            <UserActions
              isButton={true}
              data={{
                role: item?.role,
                id: item?._id,
                isBlocked: item?.isBlocked,
              }}
            />
          ),
        },
      ],
    })),
  };

  const formatDataForExcel = (results = []) => {
    return results.map((item) => ({
      name: `${item?.firstName || ''} ${item?.lastName || ''}`,
      email: item?.email,
      phone: `${item?.phone?.dialCode} ${item?.phone?.fullNumber}`,
      country: item.location.country || '',
      role: item.role || '',
    }));
  };

  const handleDownloadExcel = async () => {
    const allDataResults = data?.data?.results || []; // Adjust based on actual API response
    const excelData = formatDataForExcel(allDataResults);

    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    XLSX.writeFile(workbook, 'all_users_data.xlsx');
  };

  const handleDownloadClick = () => {
    confirmAlert({
      title: 'Confirm to download',
      message: 'Are you sure you want to download the Excel file?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDownloadExcel(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };
  return (
    <>
      <Card title="Users">
        <UserFilters
          setFiltrationData={setFiltrationData}
          filtrationData={filtrationData}
        />
        <div className="flex justify-between">
          <Button className="my-5" onClick={handleDownloadClick}>
            Download Excel
          </Button>
          <Button className="my-5" link={'/admin/create-user'}>
            + Create User
          </Button>
        </div>

        {isLoading ? (
          <Loading height="70vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </Card>
    </>
  );
};

export default UsersPage;
