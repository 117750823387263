import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { FaEye } from 'react-icons/fa6';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useGetMyProfileQuery } from '../../../redux/features/auth/authApi';
import { useGetIndividualOrdersQuery } from '../../../redux/features/products/productsApi';
import { useGetAllFavoriteStoresQuery } from '../../../redux/features/stores/storesApi';

const MyStorePage = () => {
  const storedIdentifier = localStorage.getItem('individualIdentifier');
  const { data: profileData } = useGetMyProfileQuery();

  const { data, error, isLoading } = useGetIndividualOrdersQuery(
    { emailOrUserName: storedIdentifier },
    { skip: !storedIdentifier }
  );

  const { data: favoriteStoresData } = useGetAllFavoriteStoresQuery(
    profileData?.data?.email
  );

  const orders = data?.data || [];

  const PrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="absolute left-0 z-10 -translate-y-1/2 rounded-full bg-gray-200 p-2 shadow-md hover:bg-gray-300"
      style={{ top: '50%' }}
    >
      <FaArrowLeft />
    </button>
  );

  const NextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      className="absolute right-0 z-10 -translate-y-1/2 rounded-full bg-gray-200 p-2 shadow-md hover:bg-gray-300"
      style={{ top: '50%' }}
    >
      <FaArrowRight />
    </button>
  );

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: Math.min(favoriteStoresData?.data.length, 3), // Dynamically adjust slides to show
    slidesToScroll: favoriteStoresData?.data.length > 3 ? 3 : 1, // Scroll all visible slides only if > 3
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    arrows: favoriteStoresData?.data.length > 3, // Show arrows only if more than 3 stores
    centerMode: false, // Disable center mode to prevent misalignment
    centerPadding: '0', // Remove center padding for cleaner alignment
  };

  const content = {
    header: [
      'User Email',
      'User Phone',
      'User Address',
      'Tracking ID',
      'Order Date',
      'Actions',
    ],
    body: orders.map((item) => ({
      rows: [
        { row: item.buyerEmail },
        { row: item.buyerPhoneNumber },
        { row: item.buyerAdress },
        { row: item.trackingOrderId },
        { row: new Date(item.createdAt).toLocaleDateString() },
        {
          row: (
            <Button
              link={`/my-product/${item._id}`}
              className="flex h-[40px] w-[60px] items-center justify-center !bg-blue-100"
            >
              <FaEye />
            </Button>
          ),
        },
      ],
    })),
  };

  return (
    <div className="space-y-6">
      {favoriteStoresData?.data?.length ? (
        <Card title="Favorite Stores">
          <div className="relative">
            <Slider {...sliderSettings}>
              {favoriteStoresData?.data.map((store) => (
                <div key={store?._id} className="p-4">
                  <div className="flex flex-col items-center justify-start space-y-2">
                    <Link to={`/stores/public-view/${store?._id}`}>
                      <img
                        src={store?.store_logo}
                        alt={`${store?.store_description} Logo`}
                        className="h-20 w-20 object-cover"
                      />
                    </Link>
                    <p className="text-center font-semibold">
                      {store?.store_contact_information?.store_name}
                    </p>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </Card>
      ) : (
        <Card title="Favorite Stores">
          <p className="text-center">No favorite stores yet</p>
        </Card>
      )}

      <Card title="Orders List">
        {isLoading ? (
          <Loading height="60vh" />
        ) : error ? (
          <p> {error?.data?.message || 'Unknown error'}</p>
        ) : (
          <Table content={content} pagination={null} />
        )}
      </Card>
    </div>
  );
};

export default MyStorePage;
