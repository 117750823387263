import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaCopy,
  FaFacebook,
  FaPhoneAlt,
  FaShare,
  FaWhatsapp,
} from 'react-icons/fa';
import { FaLocationDot, FaSquarePhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../constants/constants';
import features from '../../constants/fetures';
import {
  useCreateAnalyticsMutation,
  useCreateContactMutation,
} from '../../redux/features/contacts/contactsApi';
import { useGetFriendsCategoriesQuery } from '../../redux/features/friends/friendsApi';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../redux/features/subscriptions/subscriptionsApi';
import { getFeatureId } from '../../utils/helper';
import ExchangeModal from '../pages/ExchangeModal';
import DialogModal from './DialogModal';
import SocialLinks from './SocialLinks';
// import { useLocation } from 'react-router-dom';
const CardView = ({ data, link, variant, pramsBusinessType, userId }) => {
  const [createContact] = useCreateContactMutation();
  const [createAnalytic] = useCreateAnalyticsMutation();
  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalType, setModalType] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const urlToCopy = window.location.href;

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        urlToCopy
      )}`,
    },
    {
      icon: <MdEmail />,
      color: '#EA4335',
      platform: 'email',
      url: `mailto:?body=${encodeURIComponent(urlToCopy)}`,
    },
    {
      icon: <FaFacebook />,
      color: '#1877F2',
      platform: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        urlToCopy
      )}`,
    },
  ];

  const onOpenModal = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);

  const handleCopyLink = () => {
    navigator.clipboard?.writeText(urlToCopy);
    toast.success('Link copied!');
  };

  const downloadLink = `${BASE_URL}/${variant}/vcf-download/${id}`;
  const { data: myFriendCategoriesData } = useGetFriendsCategoriesQuery({
    limit: 5,
    sort: 'desc',
  });
  const onClose = () => {
    setModalType('');
  };

  const goBack = () => {
    navigate(-1);
  };

  console.log(data);
  const token = localStorage.getItem('token_link_dashboard');
  const localStorageUserId = localStorage.getItem('userId');

  const handleSubmit = async (data = {}) => {
    function separateCountryCode(phone) {
      // Use regex to match country code and the rest of the number
      const match = phone.match(/^\+(\d+)\s(.+)/);

      if (match) {
        const countryCode = match[1];
        const phoneNumber = match[2];
        return { countryCode, phoneNumber };
      } else {
        return { error: 'Invalid phone number format' };
      }
    }
    try {
      const featureUsageResponse = await patchFeatureUsage(
        getFeatureId(features.CONTACTS, featuresData?.data)
      );
      if (featureUsageResponse?.error) {
        console.log(featureUsageResponse);
        toast.error(featureUsageResponse?.error?.data?.message);
        return;
      }

      const result = separateCountryCode(data?.phone);

      const submitData = {
        contactInfo: {
          name: data?.actualName,
          phone: {
            fullNumber: result.countryCode,
            dialCode: result.phoneNumber,
          },
          email: data?.email,
        },
        photoUrl: data?.bannerImg,
        contactUserId: userId,
        categoryId: myFriendCategoriesData?.data?.results[0]?._id,
        // location: {
        //   lat: data?.location?.latitude,
        //   long: data?.location?.longitude,
        // },
      };
      const API = createContact(submitData);
      const ApiResult = await API;
      // const result = await createContact(submitData);
      if (ApiResult?.data?.success) {
        toast.success(ApiResult?.data?.message);
        navigate('/friends');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    createAnalytic({ cardId: id, type: variant });
  }, []);

  return (
    <div className="mx-auto w-full max-w-[480px] space-y-6 p-6">
      {/*{JSON.stringify(data.data)}*/}
      {/* <button
        onClick={goBack}
        className="flex h-[30px] w-[30px] items-center justify-center rounded-full !bg-white !text-black"
      >
        <IoArrowBack />
      </button> */}
      {/* <button onClick={handelSendOrderToEmail}>Send email</button> */}
      <div className=" rounded-b-xl bg-white shadow dark:bg-dark">
        <div className="relative">
          <div className="h-[137px] rounded-xl border-2  border-primary bg-gradient-to-b from-blue-50 to-blue-200 ">
            <img
              className="h-full w-full rounded-xl"
              src={data?.image}
              alt=""
            />
          </div>
          <div className="absolute -bottom-2 left-2  rounded-full  bg-primary ">
            <img
              className=" h-[116px]  w-[116px] rounded-full border border-primary"
              src={data?.bannerImg}
              alt=""
            />
          </div>
        </div>
        <div className="space-y-3 px-5 py-3">
          <div>
            {/*{data?.role === 'business' && (*/}
            {/*    <h2 className="text-[20px] font-black dark:text-white">*/}
            {/*      {data?.actualName}*/}
            {/*    </h2>*/}
            {/*)}*/}
            <h2 className="text-[20px] font-black dark:text-white">
              {pramsBusinessType === 'business'
                ? data?.businessName
                : data?.actualName}
            </h2>

            {/*<h2 className="text-[20px] font-black dark:text-white">*/}
            {/*  {data?.name}*/}
            {/*</h2>*/}

            <h4 className="font-medium capitalize"> {data?.model}</h4>
          </div>
          <div className="space-y-1 overflow-hidden border-l-2 border-primary pl-1 leading-none">
            <p className="text-[12px] font-medium text-gray-400">
              {pramsBusinessType === 'business' ? data?.actualName : data?.name}
            </p>

            <p className="text-[12px] font-medium text-gray-400">
              {data?.slogan}
            </p>

            <p className="text-[12px] font-medium text-gray-400">{data?.bio}</p>
            {/*<p className="text-[12px] font-medium text-gray-400">*/}
            {/*  {data?.email}*/}
            {/*</p>*/}
            {/* 
            <p className="text-[12px] font-medium text-gray-400">
              {data?.phone}
            </p> */}
            {/*<p className="text-[12px] font-medium text-gray-400">*/}
            {/*  {data?.whatsapp}*/}
            {/*</p>*/}
            <p className="text-[12px] font-medium text-gray-400">{data?.fax}</p>
            <p className="text-[12px] font-medium text-gray-400">
              {data?.education}
            </p>
            {/* <p className="text-[12px] font-medium text-gray-400">
              {data?.address}
            </p> */}
          </div>
          <div className="space-x-1">
            {data?.skills?.map((skill, i) => (
              <p
                key={i}
                className="inline-block rounded-full bg-[#B8BFCA4D] px-3 py-1 text-[11px] dark:text-white"
              >
                {skill}
              </p>
            ))}
          </div>

          {data?.additionalPhones?.length > 0 && (
            <div className="space-y-2  border-gray-100  text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Additional Phone Number</h4>
              {data?.additionalPhones?.map((d) => (
                <p key={d?.id} className="opacity-70">{`${d?.dialCode ?? ''} ${
                  d?.fullNumber ?? ''
                }`}</p>
              ))}
            </div>
          )}

          {data?.bankAccounts?.length > 0 && (
            <div className="space-y-2 border-b border-gray-100 pb-3 text-[14px] dark:border-gray-700">
              <h4 className="font-bold">Bank Account | IBAN</h4>
              {data?.bankAccounts?.map((d) => (
                <div key={d?.id}>
                  <p className="opacity-70">{`Bank Name: ${
                    d?.bankName ?? ''
                  }`}</p>
                  <p className="opacity-70">{`Account Name: ${
                    d?.accountName ?? ''
                  }`}</p>
                  <p className="opacity-70">{`Account No: ${
                    d?.accountNo ?? ''
                  }`}</p>
                </div>
              ))}
            </div>
          )}
          {/*<p class="whitespace-normal break-words text-center text-[12px] font-medium text-gray-400">*/}
          {/*  {data?.bio}*/}
          {/*</p>*/}
        </div>
      </div>
      <div className="space-y-3">
        <div className="flex items-center justify-center gap-5 py-2 text-[24px] text-black">
          <Link to={`tel:${data?.phone}`} target="_blank">
            <FaPhoneAlt color="#4285F4" />
          </Link>
          <Link to={`https://wa.me/${data?.whatsapp}`} target="_blank">
            <FaWhatsapp size={28} color="#25D366" />
          </Link>
          <Link to={`mailto:${data?.email}?subject=&body=`} target="_blank">
            <MdEmail size={28} color="#EA4335" />
          </Link>
          {data?.address && (
            <Link to={data?.address} target="_blank" rel="noopener noreferrer">
              <FaLocationDot size={28} color="#4285F4" />
            </Link>
          )}

          <button onClick={onOpenModal}>
            <FaShare size={24} color="#ADFF2F" />
          </button>

          {/* Share Modal */}
          {isOpenModal && (
            <DialogModal
              onClose={onCloseModal}
              title="Share Profile"
              width="500"
            >
              <div className="flex flex-col items-center gap-4 py-8">
                {/* Social Media Share Buttons */}
                <div className="flex flex-wrap gap-4">
                  {shareButtons.map((button) => (
                    <button
                      key={button.platform}
                      onClick={() => window.open(button.url, '_blank')}
                      className="rounded-full p-2 transition-transform hover:scale-110"
                      style={{ backgroundColor: button.color }}
                    >
                      {React.cloneElement(button.icon, {
                        color: 'white',
                        size: 24,
                      })}
                    </button>
                  ))}
                </div>

                {/* Copy Link */}
                <input
                  type="text"
                  value={urlToCopy}
                  readOnly
                  className="w-full rounded-md border p-2 text-center"
                  style={{ maxWidth: '400px' }}
                />
                <button
                  onClick={handleCopyLink}
                  className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
                >
                  <FaCopy size={16} />
                  Copy Link
                </button>
              </div>
              <div className="flex justify-end pt-3">
                <button
                  className="rounded bg-dark px-3 py-2 text-[14px] text-white"
                  onClick={onCloseModal}
                >
                  Close
                </button>
              </div>
            </DialogModal>
          )}
        </div>
        <div className="flex items-center justify-center gap-3">
          {variant === 'user' && id === localStorageUserId ? (
            ''
          ) : (
            <button
              onClick={() => setModalType('addContact')}
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[10px] font-bold text-black"
            >
              Add to Your Contact
            </button>
          )}
          {/* <button
            onClick={() => setModalType('addContact')}
            className="rounded-lg bg-lemon-100 px-3 py-1 text-[10px] font-bold text-black"
          >
            Add to Your Contact
          </button> */}
          <button
            onClick={() => setModalType('exchangeContact')}
            className="rounded-lg bg-green-500 px-3 py-1 text-[10px] font-bold text-black"
          >
            Exchange Contact
          </button>
          {/* && id === localStorageUserId */}
          {/*{JSON.stringify(data?.store)}*/}
          {data?.store?.length > 1 ? (
            // Render a dropdown if there are multiple stores
            <div className="dropdown">
              <select
                onChange={(e) =>
                  navigate(`/stores/public-view/${e.target.value}`)
                }
                className="rounded-lg bg-lemon-100 px-3 py-2 text-[12px] font-bold text-black"
              >
                <option value="" disabled selected>
                  Select a Store
                </option>
                {data.store.map((store) => (
                  <option key={store._id} value={store._id}>
                    {store?.store_contact_information?.store_name}{' '}
                  </option>
                ))}
              </select>
            </div>
          ) : data?.store?.length === 1 ? (
            // Render the button if there is exactly one store
            <button
              onClick={() =>
                navigate(`/stores/public-view/${data.store[0]._id}`)
              }
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
            >
              View Store
            </button>
          ) : null}

          {data?.branchId && (
            <button
              onClick={() =>
                navigate(
                  `/user/view/${data?.branchId}/?userId=${data?.branchUserId}&&isChildBranch=${data?.isChildBranch}`
                )
              }
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[10px] font-bold text-black"
            >
              View Business User
            </button>
          )}
        </div>
      </div>

      {/*{data?.youtubeVideo && (*/}
      {/*    <div className="mt-4 flex justify-center">*/}
      {/*      <a*/}
      {/*          href={data?.youtubeVideo}*/}
      {/*          target="_blank"*/}
      {/*          rel="noopener noreferrer"*/}
      {/*          className="flex items-center rounded-lg bg-red-600 px-4 py-2 text-white hover:bg-red-700"*/}
      {/*      >*/}
      {/*        <FaYoutube size={24} className="mr-2" />*/}
      {/*        <span className="text-sm font-bold">Visit YouTube Channel</span>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*)}*/}

      <SocialLinks
        //data={data?.social}
        data={{
          ...data?.social,
          email: data?.email,
          whatsapp: data?.whatsapp,
        }}
        additionalSocialLinks={data?.additionalSocialLinks}
      />

      {modalType === 'addContact' && (
        <DialogModal
          title={'Add to Your Contact'}
          width="450"
          onClose={onClose}
        >
          <div className="flex items-center justify-center gap-4">
            {token !== 'undefined' &&
            variant === 'user' &&
            id !== localStorageUserId ? (
              <div>
                <div className="flex items-center justify-center pt-4">
                  <button
                    onClick={() => handleSubmit(data)}
                    // onClick={() => alert('data')}
                    className="text-green-500"
                  >
                    <FaSquarePhone size={60} />
                  </button>
                </div>
                <p className="pt-2 text-center text-[12px] font-bold text-black">
                  Add Contact
                </p>
              </div>
            ) : (
              <div>
                <div className="flex items-center justify-center pt-4">
                  <Link
                    to={
                      variant === 'users' && token == 'undefined'
                        ? '/login'
                        : '/friends'
                    }
                    className="text-green-500"
                  >
                    <FaSquarePhone size={60} />
                  </Link>
                </div>
                <p className="pt-2 text-center text-[12px] font-bold text-black">
                  Add Contact
                </p>
              </div>
            )}

            <div>
              <div className="flex items-center justify-center pt-4">
                <Link to={downloadLink} className="text-green-500">
                  <FaSquarePhone size={60} />
                </Link>
              </div>
              <p className="pt-2 text-center text-[12px] font-bold text-black">
                Phone Contact
              </p>
            </div>
          </div>
        </DialogModal>
      )}

      {modalType === 'exchangeContact' && (
        <DialogModal
          title={'Exchange Your Contact'}
          width="500"
          onClose={onClose}
        >
          <ExchangeModal />
        </DialogModal>
      )}
    </div>
  );
};

export default CardView;
