import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';

const PlanComparisonPage = ({ data, subscriptionId, subscribedPlanName }) => {
  const navigate = useNavigate();
  const [selectedColumn, setSelectedColumn] = useState(null);

  const handleSubscription = (plan) => {
    if (plan?.planName === subscribedPlanName) {
      toast.error('You are already subscribed to this plan');
    } else if (subscribedPlanName === 'basic' && plan?.planName === 'premium') {
      toast.error('You cannot upgrade your plan directly to premium plan');
    } else {
      navigate('/plans/billing', { state: { plan: plan } });
    }
  };

  return (
    <div className="mt-4 flex flex-wrap gap-3">
      {data.length &&
        data.map((plan) => (
          <div
            key={plan._id}
            className={` flex-auto  basis-[30%] rounded border-2 ${
              selectedColumn === plan._id
                ? 'border-[#334155]'
                : 'border-gray-300'
            } bg-[#cbd5e1] text-black`}
            onClick={() => setSelectedColumn(plan._id)}
          >
            <div className="h-[67px] w-[308px]">
              <p className="ml-4 mt-4 text-[12px] font-semibold">
                {plan.planName}
              </p>
              <div className="flex">
                <h3 className="ml-4 text-[25px] font-semibold">
                  {plan.planPrice}{' '}
                  <span className="text-[18px] font-semibold">
                    {plan.currency}
                  </span>
                </h3>
              </div>
              <p className="ml-4  text-[12px] font-semibold">
                {plan.duration} Days
              </p>
            </div>
            <Button
              className={`ml-3 mt-3 h-[40px] w-[95%] bg-[#334155] text-lg text-white`}
              onClick={() => handleSubscription(plan)}
            >
              {plan?._id === subscriptionId ? 'Subscribed Plan' : 'Choose Plan'}
            </Button>
            <div className="w-[308px]">
              <ul className="ml-8 mt-8 list-none space-y-4 text-[16px]">
                {plan.features.map((feature, index) => (
                  <li key={index} className="border-b border-gray-300 pb-2">
                    <p className="font-bold">{feature.featureName}</p>
                    <p className="text-sm text-gray-600">{feature.quantity}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PlanComparisonPage;
