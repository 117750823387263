import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetCardQuery } from '../../redux/features/cards/cardsApi';
import { getFullNameByLayer } from '../../utils/func';
import Loading from '../Loading';
import CardView from '../ui/CardView';

const CardViewPage = () => {
  const { id } = useParams();
  const { data: cardData, isLoading } = useGetCardQuery(id);

  const phone = `${cardData?.data?.user?.phone?.dialCode ?? ''} ${
    cardData?.data?.user?.phone?.fullNumber ?? ''
  }`;

  const whatsapp = `${cardData?.data?.user?.whatsapp?.dialCode ?? ''} ${
    cardData?.data?.user?.whatsapp?.fullNumber ?? ''
  }`;

  console.log(cardData);

  const data = {
    //name: `${cardData?.data?.user?.firstName} ${cardData?.data?.user?.lastName}`,

    actualName: `${cardData?.data?.user?.firstName} ${cardData?.data?.user?.lastName}`,


    name: cardData?.data?.user?.businessName,
    image: cardData?.data?.user?.avatar,
    bannerImg: cardData?.data?.user?.bannerBg,
    // position: `${
    //   (cardData?.data?.user?.position ||
    //     cardData?.data?.user?.jobInformation?.position) ??
    //   ''
    // }`,
    position: cardData?.data?.user?.slogan,
    education: cardData?.data?.user?.education,
    skills: cardData?.data?.user?.skills,
    // address: cardData?.data?.user?.address,
    // email: cardData?.data?.user?.email,
    // phone: `${phone !== ' ' ? phone : ''}`,
    // additionalPhones: cardData?.data?.user?.additionalPhones,
    whatsapp: `${whatsapp !== ' ' ? whatsapp : ''}`,
    //fax: cardData?.data?.user?.faxNumber,
    bio: cardData?.data?.user?.bio,
    social: cardData?.data?.user?.socialMediaLinks,
    email: cardData?.data?.user?.email,
    additionalSocialLinks: cardData?.data?.user?.additionalSocialLinks,
    youtubeVideo: cardData?.data?.user?.socialMediaLinks?.videoLink,
    bankAccounts: cardData?.data?.user?.bankAccounts,
  };

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <CardView data={data} link={`/card/edit/${id}`} variant="company" />
      )}
    </div>
  );
};

export default CardViewPage;
