import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import { FaPenToSquare, FaPlus } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import { useLocation } from 'react-router-dom';
import Loading from '../../../../components/Loading';
import Button from '../../../../components/ui/Button';
import Card from '../../../../components/ui/Card';
import {
  useDeleteSubscriptionMutation,
  useGetAllPlansOfAdminQuery,
} from '../../../../redux/features/subscriptions/subscriptionsApi';

const SubscriptionsPage = () => {
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  const { data, isLoading, refetch } = useGetAllPlansOfAdminQuery();
  const location = useLocation();

  const handleDelete = async (id) => {
    try {
      const result = await deleteSubscription(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        refetch();
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      // Handle error
    }
  };

  useEffect(() => {
    refetch();
  }, [location.pathname]);

  return (
    <>
      <Card
        title="Subscriptions"
        content={
          <Button
            link="/admin/subscriptions/create"
            className="flex items-center gap-2 !bg-green-500"
          >
            <FaPlus /> Add New Subscription
          </Button>
        }
      >
        {isLoading ? (
          <Loading />
        ) : (
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            {data?.data.map((item) => (
              <div
                key={item.id}
                className="flex flex-col gap-3 rounded border bg-white p-4 shadow-md"
              >
                <div className="flex justify-between">
                  <h2 className=" text-lg font-bold capitalize text-black">
                    {item.duration} Days
                  </h2>
                  <h2 className=" text-lg font-bold capitalize text-black">
                    {item.planName}
                  </h2>
                  <h2 className=" text-lg font-bold capitalize text-black">
                    {item.planPrice} {item.currency}
                  </h2>
                </div>

                <p>
                  <strong>Type:</strong>{' '}
                  {item.isIndividual && item.isBusiness
                    ? 'Both'
                    : item.isBusiness
                    ? 'Business'
                    : 'Individual'}
                </p>
                <p>
                  <strong>Price:</strong> {item.planPrice} {item.currency}
                </p>
                <p>
                  <strong>Status:</strong> {item.isActive ? 'Active' : 'Trail'}
                </p>
                <p>
                  <strong>Duration:</strong> {item.duration} Days
                </p>
                {/*<strong>Feature:</strong>*/}
                {/*{item.features.map((feature) => (*/}
                {/*  <p key={feature}>*/}
                {/*    {feature?.featureName} - {feature.quantity}*/}
                {/*  </p>*/}
                {/*))}*/}

                <div className="mt-2 text-lg text-gray-500">
                  <strong>Features:</strong>
                  <ul className="list-disc pl-4">
                    {item.features.map((feature) => (
                      <li key={feature} className="text-gray-600">
                        {feature?.featureName} - {feature.quantity}
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="flex items-center gap-3">
                  <Button
                    link={`/admin/subscriptions/edit/${item._id}`}
                    className="!bg-green-400"
                  >
                    <FaPenToSquare />
                  </Button>
                  <Button
                    onClick={() => handleDelete(item._id)}
                    className="!bg-red-400"
                  >
                    <MdDelete />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </>
  );
};

export default SubscriptionsPage;
