import React from 'react';

const CustomizationPanel = ({
  setHeadingColor,
  setContentColor,
  setFontFamily,
  setTextTransform,
  fontFamily,
  textTransform,
  generatePdf,
}) => {
  return (
      <div className="w-1/4 space-y-4 border-2  border-black p-4 print:hidden">

          <div className="flex justify-center items-center">
              <button
                  onClick={() => generatePdf()}
                  className="rounded-full bg-blue-500 p-2 text-white shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 transition-all print:hidden"
              >
                  Download
              </button>
          </div>


          <h3 className="text-2xl font-semibold text-blue-950">Colors</h3>

          <div className="mb-6">
              <p className="mb-4">Select Heading Color</p>
              <div className="grid grid-cols-4 gap-2">
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-black"
                      onClick={() => setHeadingColor('text-black')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-blue-500"
                      onClick={() => setHeadingColor('text-blue-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-red-500"
                      onClick={() => setHeadingColor('text-red-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-green-500"
                      onClick={() => setHeadingColor('text-green-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-gray-500"
                      onClick={() => setHeadingColor('text-gray-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-orange-500"
                      onClick={() => setHeadingColor('text-orange-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-purple-500"
                      onClick={() => setHeadingColor('text-purple-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-pink-500"
                      onClick={() => setHeadingColor('text-pink-500')}
                  />
              </div>
          </div>

          <div>
              <p className="mb-4">Select Content Color</p>
              <div className="grid grid-cols-4 gap-2">
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-black"
                      onClick={() => setContentColor('text-black')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-blue-500"
                      onClick={() => setContentColor('text-blue-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-red-500"
                      onClick={() => setContentColor('text-red-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-green-500"
                      onClick={() => setContentColor('text-green-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-gray-500"
                      onClick={() => setContentColor('text-gray-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-orange-500"
                      onClick={() => setContentColor('text-orange-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-purple-500"
                      onClick={() => setContentColor('text-purple-500')}
                  />
                  <div
                      className="h-8 w-8 cursor-pointer rounded-full bg-pink-500"
                      onClick={() => setContentColor('text-pink-500')}
                  />
              </div>
          </div>


          <h4 className="mt-4 text-2xl font-semibold text-blue-950">Font Style</h4>
          <div className="grid gap-2">
              <button
                  className={`font-sans rounded border px-4 py-2 ${
                      fontFamily === 'font-sans' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setFontFamily('font-sans')}
              >
                  Sans-serif
              </button>
              <button
                  className={`font-serif rounded border px-4 py-2 ${
                      fontFamily === 'font-serif' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setFontFamily('font-serif')}
              >
                  Serif
              </button>
              <button
                  className={`font-mono rounded border px-4 py-2 ${
                      fontFamily === 'font-mono' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setFontFamily('font-mono')}
              >
                  Monospace
              </button>
          </div>

          <h4 className="mt-4 text-2xl font-semibold text-blue-950">
              Text Transformation
          </h4>
          <div className="grid gap-2">
              <button
                  className={`rounded border px-4 py-2 ${
                      textTransform === 'normal-case' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setTextTransform('normal-case')}
              >
                  Normal
              </button>
              <button
                  className={`rounded border px-4 py-2 uppercase ${
                      textTransform === 'uppercase' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setTextTransform('uppercase')}
              >
                  Uppercase
              </button>
              <button
                  className={`rounded border px-4 py-2 lowercase ${
                      textTransform === 'lowercase' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setTextTransform('lowercase')}
              >
                  Lowercase
              </button>
              <button
                  className={`rounded border px-4 py-2 capitalize ${
                      textTransform === 'capitalize' ? 'bg-gray-300' : ''
                  }`}
                  onClick={() => setTextTransform('capitalize')}
              >
                  Capitalize
              </button>
          </div>
      </div>
  );
};

export default CustomizationPanel;
