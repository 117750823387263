import React, { useEffect, useState } from 'react';
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import loginImg from '../../images/login/login-bg.jpg';
import logo from '../../images/logo/socalii-black-log.png';
import OtpModal from './OtpModal';

import RegistrationForm from './RegistrationForm';

const RegisterPage = () => {
  // get redirect from search aprams

  // otp started
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  // const [identifier, setIdentifier] = useState({ role: "individual", is_news_notify: true })
  const [identifier, setIdentifier] = useState({});
  const [otp, setOtp] = useState('');
  const initialTime = 0.3 * 60; // 10 minutes in seconds
  const [timeRemaining, setTimeRemaining] = useState(initialTime);
  const [isRunning, setIsRunning] = useState(false);
  useEffect(() => {
    let timer;
    if (isRunning) {
      timer = setInterval(() => {
        setTimeRemaining((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [isRunning]);

  const startTimer = () => {
    setOtp('');
    setTimeRemaining(initialTime);
    setOtpModalOpen(true);
    setIsRunning(true);
  };

  const resetTimer = () => {
    setOtp('');
    setIsRunning(false);
    setTimeRemaining(0);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  // otp end

  // redux mutations

  const onClose = () => {
    setOtpModalOpen(false);
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* {!isSuccess ?  */}
      <div className="grid w-full lg:grid-cols-2">
        <div className="flex items-center justify-center border">
          <div className="mx-auto max-w-lg p-8">
            <div className="pb-4">
              <img className="mx-auto h-12 w-auto" src={logo} alt="logo" />
            </div>
            {/* <h1 className="mb-6 text-center text-4xl font-bold">
                            Linkin<span className="font-black">Card</span>
                        </h1> */}

            <h2 className="mb-6 bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-center text-2xl font-semibold text-transparent">
              Create Free 30 day Trial Account
            </h2>

            <p className="mb-8 text-center text-gray-600">
              Create your 30 days free trial account to get started with
              Socalii. No credit card required.
            </p>

            <RegistrationForm setIdentifier={setIdentifier} />

            <p className="mt-4 text-center">
              You already have an account?{' '}
              <Link to="/login" className="text-orange-500 hover:underline">
                Login
              </Link>
            </p>

            <div className="mt-8 flex justify-center space-x-4">
              <SocialIcon Icon={FaFacebookF} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaInstagram} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaTwitter} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaYoutube} color="bg-lime-500 text-black" />
            </div>
          </div>
        </div>
        <div
          className="overflow-hidden border-8 border-lime-500 bg-green-500"
          style={{
            background: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
      {/* 
      <div className="bg-[#1a202c] flex w-full text-center justify-center items-center p-5"><h3>OTP send successfully to your email please verify your OTP!</h3></div>
      */}
      {/* otp open modal */}
      {!otpModalOpen ? null : (
        <OtpModal
          otpModalOpen={otpModalOpen}
          setOtpModalOpen={setOtpModalOpen}
          identifier={identifier}
          setIdentifier={setIdentifier}
          startTimer={startTimer}
          resetTimer={resetTimer}
          formatTime={formatTime}
          timeRemaining={timeRemaining}
          otp={otp}
          setOtp={setOtp}
          onClose={onClose}
        />
      )}
    </div>
  );
};

const SocialIcon = ({ Icon, color }) => (
  <a
    href="#"
    className={`${color} rounded-lg p-2 text-black transition duration-300 hover:opacity-80`}
  >
    <Icon />
  </a>
);

export default RegisterPage;
