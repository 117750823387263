import { Field, Form, Formik } from 'formik';
import { jwtDecode } from 'jwt-decode';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaEye,
  FaEyeSlash,
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaYoutube,
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import loginImg from '../../images/login/login-bg.jpg';
import logo from '../../images/logo/socalii-black-log.png';
import { useLoginMutation } from '../../redux/features/auth/authApi';
import { setCredentials } from '../../redux/features/auth/authSlice';

const LoginSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  password: Yup.string().required('Password is required'),
  rememberMe: Yup.boolean(),
});

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect');

  // redux mutations
  const [loginMutation, { isLoading }] = useLoginMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    delete values.rememberMe;
    try {
      localStorage.removeItem('individualIdentifier');
      let result = await loginMutation(values);
      if (result?.data?.success) {
        const { token } = result?.data?.data;
        const user = token ? jwtDecode(token) : {};
        toast.success(result?.data?.message);
        if (!Object.keys(user)?.length > 0) {
          toast.info('OTP send successfully please verify your OTP!');
        } else {
          // const identifier = values.email.includes('@') ? values.email : values.username;
          //
          // localStorage.setItem("individualIdentifier", identifier);

          const identifier = values.email.includes('@')
            ? values.email
            : user?.username || values.username;

          console.log('Identifier saved:', identifier);

          localStorage.setItem('individualIdentifier', identifier);

          dispatch(setCredentials({ user, token }));
          redirect === 'cart' ? navigate(-2) : navigate('/');
        }
      } else {
        toast.error(result?.error?.data?.message);
      }

      if (result?.error?.status === 400) {
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    } finally {
      setSubmitting(false);
    }
    resetForm();
  };

  return (
    <div className="flex min-h-screen bg-gray-100">
      <div className="grid w-full lg:grid-cols-2">
        <div className="flex items-center justify-center border">
          <div className="mx-auto max-w-lg p-8">
            <div className="pb-4">
              <img className="mx-auto h-12 w-auto" src={logo} alt="logo" />
            </div>
            {/* <h1 className="mb-6 text-center text-4xl font-bold">
              Linkin<span className="font-black">Card</span>
            </h1> */}

            <h2 className="mb-6 bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-center text-2xl font-semibold text-transparent">
              Login to your dashboard
            </h2>

            <p className="mb-8 text-center text-gray-600">
              If you don't have a Socalli yet, do yourself a favour, showcase a
              fuller and more impressive picture of who you are and make better
              connections.
            </p>

            <Formik
              initialValues={{ email: '', password: '', rememberMe: false }}
              validationSchema={LoginSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched, isSubmitting }) => (
                <Form
                  className="space-y-4"
                  // onSubmit={(e) => {
                  //     e.preventDefault();
                  // }}
                  // onSubmit={handleSubmit}
                >
                  <div className="relative">
                    <Field
                      name="email"
                      type="text"
                      placeholder="Enter username/email"
                      className={`w-full rounded-md border p-3 pl-3 pr-10 ${
                        errors.email && touched.email ? 'border-red-500' : ''
                      }`}
                    />
                    <span className="absolute right-3 top-3 text-gray-400">
                      @
                    </span>
                    {errors.email && touched.email && (
                      <div className="mt-1 text-sm text-red-500">
                        {errors.email}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <Field
                      name="password"
                      type={showPassword ? 'text' : 'password'}
                      placeholder="Enter password"
                      className={`w-full rounded-md border p-3 pr-10 ${
                        errors.password && touched.password
                          ? 'border-red-500'
                          : ''
                      }`}
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-3 text-gray-400"
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </button>
                    {errors.password && touched.password && (
                      <div className="mt-1 text-sm text-red-500">
                        {errors.password}
                      </div>
                    )}
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Field
                        type="checkbox"
                        id="rememberMe"
                        name="rememberMe"
                        className="mr-2"
                      />
                      <label
                        htmlFor="rememberMe"
                        className="text-sm text-gray-600"
                      >
                        Remember Me
                      </label>
                    </div>

                    <button
                      type="submit"
                      className="rounded-md bg-gray-800 p-3 text-white transition duration-300 hover:bg-gray-700"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Signing in...' : 'Sign in'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>

            <p className="mt-4 text-center">
              <Link
                to="/password/reset"
                className="text-orange-500 hover:underline"
              >
                Forget your password?
              </Link>
            </p>

            <p className="mt-4 text-center">
              You don't have an account?{' '}
              <Link to="/register" className="text-orange-500 hover:underline">
                Register
              </Link>
            </p>

            <div className="mt-8 flex justify-center space-x-4">
              <SocialIcon Icon={FaFacebookF} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaInstagram} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaTwitter} color="bg-lime-500 text-black" />
              <SocialIcon Icon={FaYoutube} color="bg-lime-500 text-black" />
            </div>
          </div>
        </div>
        <div
          className="overflow-hidden border-8 border-lime-500 bg-green-500 "
          style={{
            background: `url(${loginImg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>
      </div>
    </div>
  );
};

const SocialIcon = ({ Icon, color }) => (
  <a
    href="#"
    className={`${color} rounded-lg p-2 text-black transition duration-300 hover:opacity-80`}
  >
    <Icon />
  </a>
);

export default LoginPage;
