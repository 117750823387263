import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaCopy,
  FaFacebookF,
  FaPhone,
  FaShare,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { MdEmail, MdOutlineMail } from 'react-icons/md';
import { RiMessengerFill } from 'react-icons/ri';
import { SiMessenger } from 'react-icons/si';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import DialogModal from '../../../components/ui/DialogModal';
import logo from '../../../images/logo/socalii-black-log.png';
import noImage from '../../../images/shared/no-image.jpg';

import BusinessLayout from '../../../layout/BusinessLayout';
import {
  useGetMyProfileQuery,
  useLogoutMutation,
} from '../../../redux/features/auth/authApi';
import {
  useGetPostsQuery,
  useGetSinglePostQuery,
} from '../../../redux/features/posts/PostsApi';
import { getBaseUrl } from '../../../utils/helper';
import Posts from '../posts/Posts';

const HomePage = () => {
  const [isOpenModal, setIsModalOpen] = useState(false);
  const { pathname } = useLocation();
  const { id } = useParams();
  const [profileData, setProfileData] = useState({});
  const [postUserId, setPostUserId] = useState('');
  const urlToCopy = `${getBaseUrl(profileData?.card?.url)}/user/view/${
    profileData?.card?._id
  }?country-code=${profileData?.location?.country}&&type=${
    profileData?.role
  }&&username=${profileData?.username}&&isChildBranch=${false}&&userId=${
    profileData?._id
  }`;
  const isPostView = pathname.includes('/posts/view/' + id);

  const { user } = useSelector((state) => state?.auth);
  const [logout] = useLogoutMutation();
  const { data, refetch } = useGetMyProfileQuery(undefined, {
    skip: isPostView,
  });

  // const profileData = data?.data;
  const { data: singlePostData } = useGetSinglePostQuery(id, {
    skip: !isPostView,
  });
  const { data: userPosts } = useGetPostsQuery(
    { from: '/home', userId: postUserId },
    { skip: !postUserId }
  );

  useEffect(() => {
    if (!isPostView) {
      refetch(); // Trigger refetch on pathname change to update profile data
    }
    if (data?.success) {
      setProfileData(data?.data);
    }
    if (singlePostData?.success) {
      setProfileData(singlePostData?.data?.user);
    }
  }, [data, singlePostData, pathname]);

  const navigate = useNavigate();

  const handleLogOut = async () => {
    try {
      const result = await logout(user?._id);
      if (result?.data?.success) {
        localStorage.removeItem('token_link_dashboard');
        toast.success(result?.data?.message);
        navigate('/login');
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  //   modal functionality
  const onOpenModal = () => {
    setIsModalOpen(true);
  };
  const onClose = () => {
    setIsModalOpen(false);
  };

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        profileData?.card?.url
      )}`,
    },
    {
      icon: <MdEmail />,
      color: '#000000',
      platform: 'email',
      url: `mailto:?body=${encodeURIComponent(profileData?.card?.url)}`,
    },
    {
      icon: <FaFacebookF />,
      color: '#1877F2',
      platform: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        profileData?.card?.url
      )}`,
    },
    {
      icon: <RiMessengerFill />,
      color: '#0078FF',
      platform: 'messenger',
      url: `fb-messenger://share/?link=${encodeURIComponent(
        profileData?.card?.url
      )}`,
    },
    {
      icon: <SiMessenger />,
      color: '#FFA500',
      platform: 'sms',
      url: `sms:?body=${encodeURIComponent(profileData?.card?.url)}`,
    },
    {
      icon: <FaTwitter />,
      color: '#1DA1F2',
      platform: 'twitter',
      url: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        'Check this out!'
      )}&url=${encodeURIComponent(
        profileData?.card?.url || 'https://example.com'
      )}`,
    },
  ];

  const handleShare = (url) => {
    // window.open(url, '_blank');
    window.open(url);
  };

  const handelViewMap = () => {
    window.location.href = '/maps/contact';
  };

  return (
    <BusinessLayout>
      <>
        <div>
          {/* header  */}

          {/*
        <div className=" sticky top-0 z-99 flex items-center justify-between bg-white px-5 py-4 shadow">
          <Button link={"/home"} className={"!cursor-pointer !bg-transparent"}>
            <img className="mx-auto h-8 w-auto" src={logo} alt="logo" />



          </Button>
          {isPostView ? (
            <Button
              onClick={() => setPostUserId(profileData?._id)}
              className={"!bg-transparent text-lg font-semibold !text-black"}
            >
              <h2 className="cursor-pointer underline">
                {profileData?.jobInformation?.position || profileData?.fullName}
              </h2>
            </Button>
          ) : (
            <div>
              <Link
                to=""
                className=" border-b-2 border-green-500 px-3 pb-2 text-[14px] font-bold text-black"
              >
                Home
              </Link>
              <Link
                to="/posts"
                className=" px-3 pb-2 text-[14px] font-bold text-gray-500"
              >
                Posts
              </Link>
              <Link
                to="/"
                className=" px-3 pb-2 text-[14px] font-bold text-gray-500"
              >
                Dashboard
              </Link>
              <button
                onClick={handleLogOut}
                className=" px-3 pb-2 text-[14px] font-bold text-gray-500"
              >
                Logout
              </button>
            </div>
          )}
        </div>

        */}
          <div className="grid grid-cols-[400px_minmax(auto,_1fr)_300px] gap-4 space-y-6 px-4">
            <div className="pt-6">
              <div className="rounded-lg bg-white shadow-lg">
                <div className="relative bg-gray-100 ">
                  <img
                    src={
                      profileData?.avatar !==
                      'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png'
                        ? profileData?.avatar
                        : logo
                    }
                    alt="Sudaturk Logo"
                    className="mx-auto mb-2 h-[200px] rounded-t-md border pl-2 pr-2"
                  />
                  <img
                    src={profileData?.bannerBg ? profileData?.bannerBg : logo}
                    alt="Small Logo"
                    className="absolute bottom-[-50px] left-[9%] h-[70px] w-[70px] translate-x-[-50%] rounded-full"
                  />
                </div>

                <div className="space-y-4 px-3 pb-4 pt-8">
                  <div className=" text-center">
                    <h1 className="pt-6 text-xl font-bold text-gray-800">
                      {/* {profileData?.jobInformation?.companyName ||
                      profileData?.fullName} */}
                      {`${profileData?.firstName} ${profileData?.lastName}`}
                    </h1>
                    <p className="text-sm opacity-70">
                      {profileData?.jobInformation?.position ||
                        profileData?.bio}
                    </p>
                  </div>
                  {!isPostView && (
                    <div className="flex w-full items-center gap-4">
                      <Link
                        to={`/user/view/${
                          profileData?.card?._id
                        }?country-code=${
                          profileData?.location?.country
                        }&&type=${profileData?.role}&&username=${
                          profileData?.username
                        }&&isChildBranch=${false}&&userId=${profileData?._id}`}
                        className={`block w-full rounded-lg bg-primary px-4 py-2 text-center text-[14px] text-white `}
                      >
                        View Card
                      </Link>
                      <Link
                        to={`/users/edit/${profileData?.card?._id}/?userId=${profileData?._id}&&isChildBranch=${profileData?.card?.isChildBranch}`}
                        className={`block w-full rounded-lg bg-black px-4 py-2 text-center text-[14px] text-white `}
                      >
                        Edit Profile
                      </Link>
                    </div>
                  )}

                  {isPostView ? null : (
                    <Button
                      onClick={() => handelViewMap()}
                      className="!flex w-full !justify-center border border-black !bg-white !text-black"
                    >
                      Nearby Contacts
                    </Button>
                  )}

                  <div className="rounded border ">
                    <Button className="flex w-full items-center  gap-2 rounded-none !rounded-b border !bg-white !text-black">
                      <FaPhone className="text-lemon-100" />
                      <span>{profileData?.phone?.fullNumber}</span>
                    </Button>
                    {isPostView ? null : (
                      <Button className="flex w-full items-center  gap-2 rounded-none !rounded-b border !bg-white !text-black">
                        <MdOutlineMail size={18} className="text-lemon-100" />
                        <span>{profileData?.email}</span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Posts
              isPostView={isPostView}
              singlePostData={
                userPosts?.data?.results || [{ ...singlePostData?.data }]
              }
            />
            <div>
              <Card>
                <img
                  className="mx-auto"
                  src={
                    profileData?.card?.qrImage ||
                    singlePostData?.data?.card?.qrImage ||
                    noImage
                  }
                  alt=""
                />
                {isPostView ? null : (
                  <div className="mt-3 flex justify-center">
                    <Button
                      className="flex items-center gap-1 !bg-lemon-100 !px-3 !py-1"
                      onClick={onOpenModal}
                    >
                      <FaShare />
                      <span>Share</span>
                    </Button>
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>

        {/*  */}

        {isOpenModal && (
          <DialogModal onClose={onClose} title="Share My Profile" width="500">
            <div className="flex flex-col items-center gap-4 py-8">
              <div className="flex flex-wrap gap-4">
                {shareButtons.map((button) => (
                  <button
                    key={button.platform}
                    onClick={() => {
                      if (button.action) {
                        button.action();
                      } else {
                        window.open(button.url, '_blank');
                      }
                    }}
                    className="rounded-full p-2 transition-transform hover:scale-110"
                    style={{ backgroundColor: button.color }}
                  >
                    {React.cloneElement(button.icon, {
                      color: 'white',
                      size: 24,
                    })}
                  </button>
                ))}
              </div>

              <input
                type="text"
                value={urlToCopy}
                readOnly
                className="w-full rounded-md border p-2 text-center"
                style={{ maxWidth: '400px', height: '80px' }}
              />

              <button
                onClick={() => {
                  navigator.clipboard?.writeText(urlToCopy);
                  toast.success('Link copied!');
                }}
                className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
              >
                <FaCopy size={16} />
                Copy Link
              </button>
            </div>
            <div className="flex justify-end pt-3">
              <button
                className="rounded bg-dark px-3 py-2 text-[14px] text-white"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </DialogModal>
        )}
      </>
    </BusinessLayout>
  );
};

export default HomePage;
