import { useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import noImg from '../../../images/shared/no-image.jpg';
import {
  useDeleteFriendRequestMutation,
  useGetMySentFriendRequestsQuery,
} from '../../../redux/features/friends/friendsApi';
import { getFullName } from '../../../utils/func';
import Loading from '../../Loading';
import Pagination from '../../ui/Pagination';
import SearchBar from '../../ui/SearchBar';
const FriendYouHaveRequested = ({ isSingleView = false, limit = 10 }) => {
  const [searchText, setSearchText] = useState('');
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: limit,
  });
  const [currentUsingId, setCurrentUsingId] = useState(null);

  const { data: usersData, refetch } = useGetMySentFriendRequestsQuery({
    ...paginate,
    q: searchText,
  });
  const [deleteRequest, { isLoading: isDeleteLoading }] =
    useDeleteFriendRequestMutation();

  const handleDeleteRequest = async (id) => {
    setCurrentUsingId(id);
    const submitData = {
      requestId: id,
    };

    try {
      const result = await deleteRequest(submitData);
      if (result?.data?.success) {
        refetch();
        toast.success(result?.data?.message);
      } else {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.detail);
    } finally {
      setCurrentUsingId(null);
    }
  };

  return (
    <div className="rounded-lg bg-white p-4 text-black shadow-md dark:bg-dark dark:text-gray-300">
      <div className="mb-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">Friends You Have Sent Requests</h2>
        {isSingleView ? null : (
          <Link
            to={'/friends/all/sent-requests'}
            className="text-blue-600 hover:underline"
          >
            See all
          </Link>
        )}
      </div>
      <div className="mb-4 max-w-md p-3 ps-0 shadow-current">
        <SearchBar onSearch={(e) => setSearchText(e)} debounceTime={1500} />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-5">
        {usersData?.data?.results?.map((person, index) => (
          <div
            key={index}
            className="overflow-hidden rounded-lg border border-gray-200 bg-white text-black dark:bg-dark dark:text-gray-300"
          >
            <Link to={`/social/profile/${person?.friend?._id}`}>
              <img
                src={person?.friend?.avatar || noImg}
                alt={getFullName(person)}
                className="h-32 w-full object-cover"
              />
            </Link>
            <div className="p-4">
              <Link to={`/social/profile/${person?.friend?._id}`}>
                <h3 className="mb-1 text-lg font-semibold">
                  {getFullName(person) || '-- --'}
                </h3>
              </Link>
              <p className="mb-3 text-sm text-gray-600">
                {person?.mutualFriendCount} mutual friend
                {person?.mutualFriendCount !== 1 && 's'}
              </p>
              <button
                className="flex w-full items-center justify-center rounded bg-gray-100 px-2 py-1 text-gray-600"
                onClick={() => handleDeleteRequest(person?.requestId)}
              >
                {isDeleteLoading && currentUsingId === person?.requestId ? (
                  <Loading variant="button" height="20px" />
                ) : (
                  'Cancel'
                )}
              </button>
            </div>
          </div>
        ))}
      </div>
      {usersData?.data?.paginate?.totalCount > 10 && (
        <Pagination
          pagination={usersData?.data?.paginate}
          onPaginate={setPaginate}
        />
      )}
    </div>
  );
};

export default FriendYouHaveRequested;
