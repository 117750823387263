import React, { useEffect, useState } from 'react';
import {FaChevronLeft, FaChevronRight, FaCopy, FaFacebook, FaShare, FaTimes, FaWhatsapp} from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateAnalyticsMutation } from '../../redux/features/contacts/contactsApi';
import Button from '../ui/Button';
import DialogModal from "../ui/DialogModal";
import {MdEmail} from "react-icons/md";
import toast from "react-hot-toast";

const CarView = ({ data, link, variant, isPublic }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createAnalytic] = useCreateAnalyticsMutation();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const urlToCopy = window.location.href;

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
          urlToCopy
      )}`,
    },
    {
      icon: <MdEmail />,
      color: '#EA4335',
      platform: 'email',
      url: `mailto:?body=${encodeURIComponent(urlToCopy)}`,
    },
    {
      icon: <FaFacebook />,
      color: '#1877F2',
      platform: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          urlToCopy
      )}`,
    },
  ];

  const onOpenModal = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);

  const handleCopyLink = () => {
    navigator.clipboard?.writeText(urlToCopy);
    toast.success('Link copied!');
  };

  const images = data?.coverImage || [];

  const handleNextImage = () => {
    if (currentImageIndex < images.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrevImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex - 1);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    createAnalytic({ cardId: id, type: variant });
  }, []);

  return (
    <div className="mx-auto w-full max-w-[480px] space-y-6 p-6">
      <div className="mb-4 overflow-hidden rounded-xl shadow-lg">
        <div className="relative h-[300px] bg-gray-200">
          {images.length > 0 && (
            <img
              className="h-full w-full cursor-pointer object-cover"
              src={images[currentImageIndex]}
              alt="Car"
              onClick={openModal}
            />
          )}
          <button
            onClick={handlePrevImage}
            disabled={currentImageIndex === 0}
            className={`absolute left-2 top-1/2 -translate-y-1/2 transform ${
              currentImageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
            }`}
          >
            <FaChevronLeft size={24} />
          </button>
          <button
            onClick={handleNextImage}
            disabled={currentImageIndex === images.length - 1}
            className={`absolute right-2 top-1/2 -translate-y-1/2 transform ${
              currentImageIndex === images.length - 1
                ? 'cursor-not-allowed opacity-50'
                : ''
            }`}
          >
            <FaChevronRight size={24} />
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="fixed  inset-0 flex items-center justify-center bg-black ">
          <div className="relative">
            <img
              className="h-[80vh] w-[60vw]   object-contain"
              src={images[currentImageIndex]}
              alt="Full-size Car"
            />
            <button
              onClick={closeModal}
              className="absolute right-2 top-2  text-2xl text-white"
            >
              <FaTimes />
            </button>
            <button
              onClick={handlePrevImage}
              disabled={currentImageIndex === 0}
              className={`absolute left-4 top-1/2 -translate-y-1/2 transform text-white ${
                currentImageIndex === 0 ? 'cursor-not-allowed opacity-50' : ''
              }`}
            >
              <FaChevronLeft size={32} />
            </button>
            <button
              onClick={handleNextImage}
              disabled={currentImageIndex === images.length - 1}
              className={`absolute right-4 top-1/2 -translate-y-1/2 transform text-white ${
                currentImageIndex === images.length - 1
                  ? 'cursor-not-allowed opacity-50'
                  : ''
              }`}
            >
              <FaChevronRight size={32} />
            </button>
          </div>
        </div>
      )}

      <div className="space-y-4 rounded-lg bg-white p-6 shadow-lg">
        <h2 className="text-2xl font-bold text-gray-800">{data?.name}</h2>
        <p className="text-lg font-medium text-gray-500">
          Model: {data?.model}
        </p>
        <p className="text-lg font-semibold text-primary">
          Price: {data?.price}
        </p>

        <div className="mt-4 space-y-2">
          <h3 className="text-xl font-semibold text-gray-800">Details</h3>
          <p className="text-gray-600">Kilometers Average: {data?.position}</p>
          <p className="text-gray-600">Engine Size: {data?.phone}</p>
          <p className="text-gray-600">From: {data?.address}</p>

          <div className="flex  justify-between items-center">
          <Button className="flex" onClick={() => navigate(`/user/view/${data.dealerProfile}`)}>
            View Dealer
          </Button>

          {isPublic && (
              <div className="flex ">
              <Button  className="flex items-center gap-2" onClick={onOpenModal}>
                <span>Share</span>
                <FaShare size={16}/>
              </Button>
              </div>
          )}
          </div>
          {isOpenModal && (
              <DialogModal
                  onClose={onCloseModal}
                  title="Share Car"
                  width="500"
              >
                <div className="flex flex-col items-center gap-4 py-8">
                  {/* Social Media Share Buttons */}
                  <div className="flex flex-wrap gap-4">
                    {shareButtons.map((button) => (
                        <button
                            key={button.platform}
                            onClick={() => window.open(button.url, '_blank')}
                            className="rounded-full p-2 transition-transform hover:scale-110"
                            style={{ backgroundColor: button.color }}
                        >
                          {React.cloneElement(button.icon, {
                            color: 'white',
                            size: 24,
                          })}
                        </button>
                    ))}
                  </div>

                  {/* Copy Link */}
                  <input
                      type="text"
                      value={urlToCopy}
                      readOnly
                      className="w-full rounded-md border p-2 text-center"
                      style={{ maxWidth: '400px' }}
                  />
                  <button
                      onClick={handleCopyLink}
                      className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
                  >
                    <FaCopy size={16} />
                    Copy Link
                  </button>
                </div>
                <div className="flex justify-end pt-3">
                  <button
                      className="rounded bg-dark px-3 py-2 text-[14px] text-white"
                      onClick={onCloseModal}
                  >
                    Close
                  </button>
                </div>
              </DialogModal>
          )}
        </div>
      </div>

      {data?.youtubeVideo && (
        <div
          className="relative mt-4"
          style={{ paddingBottom: '56.25%', height: 0 }}
        >
          <iframe
            title="YouTube video"
            src={`https://www.youtube.com/embed/${data?.youtubeVideo}`}
            className="absolute left-0 top-0 h-full w-full rounded-lg"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default CarView;
