import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import {
  FaCopy,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaPhoneAlt,
  FaShare,
  FaTwitter,
  FaWhatsapp,
  FaYoutube,
} from 'react-icons/fa';
import { FaLocationDot, FaSquarePhone } from 'react-icons/fa6';
import { MdEmail } from 'react-icons/md';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BASE_URL } from '../../constants/constants';
import features from '../../constants/fetures';
import {
  useCreateAnalyticsMutation,
  useCreateContactMutation,
} from '../../redux/features/contacts/contactsApi';
import { useGetFriendsCategoriesQuery } from '../../redux/features/friends/friendsApi';
import {
  useGetAllFeaturesOfApplicationQuery,
  usePatchFeatureUsageMutation,
} from '../../redux/features/subscriptions/subscriptionsApi';
import { getFeatureId } from '../../utils/helper';
import ExchangeModal from '../pages/ExchangeModal';
import DialogModal from './DialogModal';
import SocialLinks from './SocialLinks';

const CardClassicView = ({
  data,
  link,
  variant,
  pramsBusinessType,
  isPublic,
  userId,
}) => {
  const [createContact] = useCreateContactMutation();
  const [createAnalytic] = useCreateAnalyticsMutation();
  const [patchFeatureUsage, { isLoading: isPatchFeatureUsageLoading }] =
    usePatchFeatureUsageMutation();
  const { data: featuresData, isLoading: isFeaturesLoading } =
    useGetAllFeaturesOfApplicationQuery();
  const { id } = useParams();
  const navigate = useNavigate();
  const [modalType, setModalType] = useState('');

  const [isOpenModal, setIsOpenModal] = useState(false);
  const urlToCopy = window.location.href;

  const shareButtons = [
    {
      icon: <FaWhatsapp />,
      color: '#25D366',
      platform: 'whatsapp',
      url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        urlToCopy
      )}`,
    },
    {
      icon: <MdEmail />,
      color: '#EA4335',
      platform: 'email',
      url: `mailto:?body=${encodeURIComponent(urlToCopy)}`,
    },
    {
      icon: <FaFacebook />,
      color: '#1877F2',
      platform: 'facebook',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        urlToCopy
      )}`,
    },
  ];

  const onOpenModal = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);

  const handleCopyLink = () => {
    navigator.clipboard?.writeText(urlToCopy);
    toast.success('Link copied!');
  };

  const downloadLink = `${BASE_URL}/${variant}/vcf-download/${id}`;
  const { data: myFriendCategoriesData } = useGetFriendsCategoriesQuery({
    limit: 5,
    sort: 'desc',
  });

  const onClose = () => {
    setModalType('');
  };

  const goBack = () => {
    navigate(-1);
  };

  const localStorageUserId = localStorage.getItem('userId');
  const token = localStorage.getItem('token_link_dashboard');

  const handleSubmit = async (data = {}) => {
    function separateCountryCode(phone) {
      // Use regex to match country code and the rest of the number
      const match = phone ? phone.match(/^\+(\d+)\s(.+)/) : null;
      if (match) {
        const countryCode = match[1];
        const phoneNumber = match[2];
        return { countryCode, phoneNumber };
      } else {
        return { error: 'Invalid phone number format' };
      }
    }
    try {
      const result = separateCountryCode(data?.phone);

      const submitData = {
        contactInfo: {
          name: data?.actualName,
          phone: {
            fullNumber: result?.countryCode,
            dialCode: result?.phoneNumber,
          },
          email: data?.email,
        },
        photoUrl: data?.bannerImg,
        contactUserId: userId,
        categoryId: myFriendCategoriesData?.data?.results[0]?._id,
        // location: {
        //   lat: data?.location?.latitude,
        //   long: data?.location?.longitude,
        // },
      };
      const featureUsageResponse = await patchFeatureUsage(
        getFeatureId(features.CONTACTS, featuresData?.data)
      );
      if (featureUsageResponse?.error) {
        console.log(featureUsageResponse);
        toast.error(featureUsageResponse?.error?.data?.message);
        return;
      }
      const API = createContact(submitData);
      const ApiResult = await API;
      // const result = await createContact(submitData);
      if (ApiResult?.data?.success) {
        toast.success(ApiResult?.data?.message);
        navigate('/friends');
      } else {
        toast.error(ApiResult.error?.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || 'An error occurred');
    }
  };

  useEffect(() => {
    createAnalytic({ cardId: id, type: variant });
  }, []);
  //   console.log(
  //     token !== 'undefined' && variant === 'user' && id !== localStorageUserId
  //   );
  return (
    <div className="mx-auto w-full max-w-[800px] p-6">
      {/* {JSON.stringify(data)} */}
      <div className="overflow-hidden rounded-lg bg-white shadow-lg">
        <div className="relative h-[300px] w-full bg-gradient-to-b from-blue-50 to-blue-200">
          <img
            src={data?.image}
            alt=""
            className="h-full w-full object-cover"
          />
          <div className="absolute -bottom-[60px] left-1/2 -translate-x-1/2 transform rounded-full bg-primary p-2">
            <img
              src={data?.bannerImg}
              alt="Profile"
              className="h-[120px] w-[120px] rounded-full border-4 border-white object-cover"
            />
          </div>
        </div>

        <div className="pb-6 pt-20 text-center">
          <h2 className="text-2xl font-bold">
            {pramsBusinessType === 'business'
              ? data?.businessName
              : data?.actualName}
          </h2>
          <h2 className="text-md font-bold">
            {pramsBusinessType === 'business' ? data?.actualName : data?.name}
          </h2>
          <p className="text-gray-500">{data?.slogan}</p>
          <p className="text-gray-500">{data?.model}</p>

          <div className="mt-4">
            {/*<p className="text-sm text-gray-500">{data?.email}</p>*/}
            {/* <p className="text-sm text-gray-500">{data?.phone}</p> */}
            {/*<p className="text-sm text-gray-500">{data?.whatsapp}</p>*/}
            <p className="text-sm text-gray-500">{data?.fax}</p>
            <p className="text-sm text-gray-500">{data?.education}</p>
            {/* <p className="text-sm text-gray-500">{data?.address}</p> */}
          </div>
        </div>

        <div className="flex justify-center gap-6 p-2 text-center">
          <Link to={`tel:${data?.phone}`}>
            <FaPhoneAlt size={24} color="#4285F4" />
          </Link>
          <Link to={`https://wa.me/${data?.whatsapp}`}>
            <FaWhatsapp size={24} color="#25D366" />
          </Link>
          <Link to={`mailto:${data?.email}`}>
            <MdEmail size={24} color="#EA4335" />
          </Link>
          {data?.address && (
            <Link to={data?.address} target="_blank" rel="noopener noreferrer">
              <FaLocationDot size={24} color="#4285F4" />
            </Link>
          )}

          {isPublic && (
            <button onClick={onOpenModal}>
              <FaShare size={24} color="#ADFF2F" />
            </button>
          )}

          {/* Share Modal */}
          {isOpenModal && (
            <DialogModal
              onClose={onCloseModal}
              title="Share Profile"
              width="500"
            >
              <div className="flex flex-col items-center gap-4 py-8">
                {/* Social Media Share Buttons */}
                <div className="flex flex-wrap gap-4">
                  {shareButtons.map((button) => (
                    <button
                      key={button.platform}
                      onClick={() => window.open(button.url, '_blank')}
                      className="rounded-full p-2 transition-transform hover:scale-110"
                      style={{ backgroundColor: button.color }}
                    >
                      {React.cloneElement(button.icon, {
                        color: 'white',
                        size: 24,
                      })}
                    </button>
                  ))}
                </div>

                {/* Copy Link */}
                <input
                  type="text"
                  value={urlToCopy}
                  readOnly
                  className="w-full rounded-md border p-2 text-center"
                  style={{ maxWidth: '400px' }}
                />
                <button
                  onClick={handleCopyLink}
                  className="flex items-center gap-1 rounded-md bg-blue-500 px-4 py-2 text-white transition-transform hover:scale-105"
                >
                  <FaCopy size={16} />
                  Copy Link
                </button>
              </div>
              <div className="flex justify-end pt-3">
                <button
                  className="rounded bg-dark px-3 py-2 text-[14px] text-white"
                  onClick={onCloseModal}
                >
                  Close
                </button>
              </div>
            </DialogModal>
          )}
        </div>

        <div className="flex items-center justify-center gap-3 py-4">
          <button
            onClick={() => setModalType('addContact')}
            className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
          >
            Add to Your Contact
          </button>
          <button
            onClick={() => setModalType('exchangeContact')}
            className="rounded-lg bg-green-500 px-3 py-1 text-[10px] font-bold text-black"
          >
            Exchange Contact
          </button>
          {/* {data?.store?.length > 0 && (
            <button
              onClick={() =>
                navigate(`/stores/public-view/${data.store[0]._id}`)
              }
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
            >
              View Store
            </button>
          )} */}
          {data?.store?.length > 1 ? (
            // Render a dropdown if there are multiple stores
            <div className="dropdown">
              <select
                onChange={(e) =>
                  navigate(`/stores/public-view/${e.target.value}`)
                }
                className="rounded-lg bg-lemon-100 px-3 py-2 text-[12px] font-bold text-black"
              >
                <option value="" disabled selected>
                  Select a Store
                </option>
                {data.store.map((store) => (
                  <option key={store._id} value={store._id}>
                    {store?.store_contact_information?.store_name}{' '}
                    {/* Replace 'name' with the property representing the store's display name */}
                  </option>
                ))}
              </select>
            </div>
          ) : data?.store?.length === 1 ? (
            // Render the button if there is exactly one store
            <button
              onClick={() =>
                navigate(`/stores/public-view/${data.store[0]._id}`)
              }
              className="rounded-lg bg-lemon-100 px-3 py-1 text-[12px] font-bold text-black"
            >
              View Store
            </button>
          ) : null}
        </div>

        <div className="mt-4 p-3 text-center">
          <div className="flex justify-center gap-6">
            {data?.facebook && (
              <a
                href={data?.facebook}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebook
                  size={24}
                  className="text-blue-600 hover:text-blue-800"
                />
              </a>
            )}
            {data?.twitter && (
              <a href={data?.twitter} target="_blank" rel="noopener noreferrer">
                <FaTwitter
                  size={24}
                  className="text-blue-400 hover:text-blue-600"
                />
              </a>
            )}
            {data?.instagram && (
              <a
                href={data?.instagram}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram
                  size={24}
                  className="text-pink-600 hover:text-pink-800"
                />
              </a>
            )}
            {data?.linkedin && (
              <a
                href={data?.linkedin}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin
                  size={24}
                  className="text-blue-700 hover:text-blue-900"
                />
              </a>
            )}
            {data?.youtubeChannel && (
              <a
                href={data?.youtubeChannel}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube
                  size={24}
                  className="text-red-600 hover:text-red-800"
                />
              </a>
            )}
          </div>
        </div>

        {/*{data?.youtubeVideo && (*/}
        {/*    <div*/}
        {/*        style={{*/}
        {/*            position: 'relative',*/}
        {/*            paddingBottom: '56.25%',*/}
        {/*            height: 0,*/}
        {/*            overflow: 'hidden',*/}
        {/*            maxWidth: '100%',*/}
        {/*            background: '#000',*/}
        {/*            borderRadius: '10px',*/}
        {/*            marginTop: '20px', // Space before the video*/}
        {/*        }}*/}
        {/*    >*/}
        {/*        <iframe*/}
        {/*            title="YouTube video"*/}
        {/*            src={`https://www.youtube.com/embed/${data?.youtubeVideo}`}*/}
        {/*            style={{*/}
        {/*                position: 'absolute',*/}
        {/*                top: 0,*/}
        {/*                left: 0,*/}
        {/*                width: '100%',*/}
        {/*                height: '100%',*/}
        {/*            }}*/}
        {/*            frameBorder="0"*/}
        {/*            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
        {/*            allowFullScreen*/}
        {/*        ></iframe>*/}
        {/*    </div>*/}
        {/*)}*/}

        {modalType === 'addContact' && (
          <DialogModal
            title="Add to Your Contact"
            width="450"
            onClose={onClose}
          >
            <div className="flex items-center justify-center gap-4">
              {token !== 'undefined' &&
              variant === 'user' &&
              id !== localStorageUserId ? (
                <div>
                  <div className="flex items-center justify-center pt-4">
                    <button
                      onClick={() => handleSubmit(data)}
                      className="text-green-500"
                    >
                      <FaSquarePhone size={60} />
                    </button>
                  </div>
                  <p className="pt-2 text-center text-[12px] font-bold text-black">
                    Add Contact
                  </p>
                </div>
              ) : (
                <div>
                  <div className="flex items-center justify-center pt-4">
                    <Link
                      to={
                        variant === 'users' && token == 'undefined'
                          ? '/login'
                          : '/friends'
                      }
                      className="text-green-500"
                    >
                      <FaSquarePhone size={60} />
                    </Link>
                  </div>
                  <p className="pt-2 text-center text-[12px] font-bold text-black">
                    Add Contact
                  </p>
                </div>
              )}

              <div>
                <div className="flex items-center justify-center pt-4">
                  <Link to={downloadLink} className="text-green-500">
                    <FaSquarePhone size={60} />
                  </Link>
                </div>
                <p className="pt-2 text-center text-[12px] font-bold text-black">
                  Phone Contact
                </p>
              </div>
            </div>
          </DialogModal>
        )}

        {modalType === 'exchangeContact' && (
          <DialogModal
            title="Exchange Your Contact"
            width="500"
            onClose={onClose}
          >
            <ExchangeModal />
          </DialogModal>
        )}
      </div>
      <SocialLinks
        //data={data?.social}
        data={{
          ...data?.social,
          email: data?.email,
          whatsapp: data?.whatsapp,
        }}
        additionalSocialLinks={data?.additionalSocialLinks}
      />
    </div>
  );
};

export default CardClassicView;
