import React from 'react';
import StatCard from './cards/StatCard';

const AdminDashboard = () => {
  return (
    <div>
      {/* Main cards */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
        <StatCard title="Total Users" value={10} />
        <StatCard title="Total Individuals" value={20} />
        <StatCard title="Total Business" value={30} />
        <StatCard title="Total Branches" value={40} />
      </div>
    </div>
  );
};

export default AdminDashboard;
