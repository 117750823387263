import React, { useEffect, useState } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Route, Routes, useParams } from 'react-router-dom';
import AgentLayout from './layout/AgentLayout';
import DefaultLayout from './layout/DefaultLayout';
import IndividualLayout from './layout/IndividualLayout';
import StoreLayout from './layout/StoreLayout';
import ClientsAll from './modules/agents/ClientsAll';
import CreateNewClient from './modules/agents/CreateNewClient';
import CreateQR from './modules/agents/CreateQR';
import Dashboard from './modules/agents/Dashboard';
import DemoAccounts from './modules/agents/DemoAccounts';
import HotSection from './modules/agents/HotSection';
import OrdersReport from './modules/agents/OrdersReport';
import QRPlayground from './modules/agents/QRPlayground';
import WebMessages from './modules/agents/WebMessages';
import AddressPage from './modules/business/address/AddressPage';
import CreateAddressPage from './modules/business/address/CreateAddressPage';
import AnalyticsPage from './modules/business/cards/AnalyticsPage';
import CardsPage from './modules/business/cards/CardsPage';
import EditCardPage from './modules/business/cards/EditCardPage';
import TeamCardsPage from './modules/business/cards/TeamCardsPage';
import AllCarsPage from './modules/business/cars/AllCarsPage';

import CardViewPage from './components/pages/CardViewPage';
import CreateCarsPage from './components/pages/CreateCarsPage';
import DashboardPage from './components/pages/DashboardPage';
import EmployeeCardViewPage from './components/pages/EmpoyeeCardViewPage';
import TeamCardViewPage from './components/pages/TeamCardViewPage';
import PrivateRoute from './components/PrivateRoute';
import AdminLayout from './layout/AdminLayout';
import CommonLayout from './layout/CommonLayout';
import CreateSubscriptionPage from './modules/admin/subscriptions/Plans/CreateSubscriptionsPage';
import EditSubscriptionPage from './modules/admin/subscriptions/Plans/EditSubscriptionPage';
import SubscriptionsPage from './modules/admin/subscriptions/Plans/SubscriptionsPage';
import UsersPage from './modules/admin/users/UsersPage';
import EditClient from './modules/agents/EditClient';
import ForgetPasswordPage from './modules/authentication/ForgetPasswordPage';
import LoginPage from './modules/authentication/LoginPage';
import RegisterPage from './modules/authentication/RegisterPage';
import EditAddressPage from './modules/business/address/EditAddressPage';
import CreateCardPage from './modules/business/cards/CreateCardPage';
import CreateTeamCardPage from './modules/business/cards/CreateTeamCardPage';
import EmployeeCreatePage from './modules/business/employees/EmployeeCreatePage';
import EmployeeEditPage from './modules/business/employees/EmployeeEditPage';
import EmployeesPage from './modules/business/employees/EmployeesPage';
import HomePage from './modules/business/home/HomePage';
import InterestsPage from './modules/business/interests/InterestsPage';
import EditPost from './modules/business/posts/EditPost';
import PostsPage from './modules/business/posts/PostsPage';
import SettingsPage from './modules/business/settings/SettingsPage';
import StaticsPage from './modules/business/statics/StaticsPage';
import CartPage from './modules/business/store/CartPage';
import CreateCategoriesPage from './modules/business/store/CreateCategoriesPage';
import CreateProductPage from './modules/business/store/CreateProductPage';
import CreateStorePage from './modules/business/store/CreateStorePage';
import EditCategoriesPage from './modules/business/store/EditCategoriesPage';
import EditProductPage from './modules/business/store/EditProductPage';
import EditStorePage from './modules/business/store/EditStorePage';
import MyStorePage from './modules/business/store/MyStorePage';
import ProductCategoriesPage from './modules/business/store/ProductCategoriesPage';
import ProductsPage from './modules/business/store/ProductsPage';
import StorePreviewPage from './modules/business/store/StorePreviewPage';
import StoreReviewPage from './modules/business/store/StoreReviewPage';
import WifiQrCreate from './modules/business/wifi/CreateWifiQr';
import WifiQrPage from './modules/business/wifi/WifiQrPage';
import IndividualAddresses from './modules/individual/address/AddressPage';
import IndividualCreateAddresses from './modules/individual/address/CreateAddressPage';
import IndividualEditAddresses from './modules/individual/address/EditAddresses';
import IndividualCards from './modules/individual/cards/CardsPage';
import IndividualEditCard from './modules/individual/cards/EditCardPage';
import IndividualViewCardPage from './modules/individual/cards/ViewCardPage';
import IndividualCars from './modules/individual/cars/CarsPage';
import IndividualHome from './modules/individual/home/HomePage';
import IndividualInterests from './modules/individual/interests/InterestsPage';
import IndividualPosts from './modules/individual/posts/PostsPage';
import IndividualSettings from './modules/individual/settings/SettingsPage';
import IndividualStatics from './modules/individual/statics/StaticsPage';
import IndividualCreateProductCategories from './modules/individual/store/CreateCategoriesPage';
import IndividualStoreCreate from './modules/individual/store/CreateStorePage';
import IndividualEditCategoriesPage from './modules/individual/store/EditCategoriesPage';
import IndividualMyStore from './modules/individual/store/MyStorePage';
import IndividualProductCategories from './modules/individual/store/ProductCategoriesPage';
import IndividualProducts from './modules/individual/store/ProductsPage';
import IndividualStoreReviews from './modules/individual/store/StoreReviewPage';
import IndividualStoreUpdate from './modules/individual/store/UpdateStorePage';
import IndividualWifiQrCreate from './modules/individual/wifi/CreateWifiQr';
import IndividualWifiQr from './modules/individual/wifi/WifiQrPage';
import StoreAddresses from './modules/store/address/AddressPage';
import StoreCreateAddresses from './modules/store/address/CreateAddressPage';
import StoreEditAddresses from './modules/store/address/EditAddresses';
import StoreCards from './modules/store/cards/CardsPage';
import StoreCreateCard from './modules/store/cards/CreateCardPage';
import StoreEditCard from './modules/store/cards/EditCardPage';
import StoreProfileQuestion from './modules/store/cards/ProfileQuestionPage';
import StoreCardView from './modules/store/cards/ViewCardPage';
import StoreCars from './modules/store/cars/CarsPage';
import StoreHome from './modules/store/home/HomePage';
import StoreInterests from './modules/store/interests/InterestsPage';
import StorePosts from './modules/store/posts/PostsPage';
import {
  //   default as SettingsPage,
  default as StoreSettings,
} from './modules/store/settings/SettingsPage';
import StoreStatics from './modules/store/statics/StaticsPage';
import StoreCreateProductCategories from './modules/store/store/CreateCategoriesPage';
import StoreStoreCreate from './modules/store/store/CreateStorePage';
import StoreEditCategoriesPage from './modules/store/store/EditCategoriesPage';
import StoreMyStore from './modules/store/store/MyStorePage';
import StoreProductCategories from './modules/store/store/ProductCategoriesPage';
import StoreProducts from './modules/store/store/ProductsPage';
import StoreStoreReviews from './modules/store/store/StoreReviewPage';
import StoreStoreUpdate from './modules/store/store/UpdateStorePage';
import CreateWifiQr from './modules/store/wifi/CreateWifiQr';
import StoreWifiQr from './modules/store/wifi/WifiQrPage';
// Example import statement in App.jsx
import BirthdayNotificationsPage from './components/pages/BirthDayNotificationsPage';
import CarCardViewPage from './components/pages/CarViewPage';
import AddNewContactPage from './components/pages/contact/AddContactPage';
import EditContactPage from './components/pages/contact/EditContactPage';
import FriendsPage from './components/pages/FriendsPage';
import CreateFriendCategories from './components/pages/friendsPage/CreateFriendCategories';
import EditFriendsCategories from './components/pages/friendsPage/EditFriendsCategories';
import FriendCategoriesList from './components/pages/friendsPage/FreindCategoriesList';
import NearbyLocationDiscounts from './components/pages/NearbyLocationDiscounts';
import SeeAllFriendsPage from './components/pages/SeeAllFriendsPage';
import SocialProfileViewPage from './components/pages/SocialProfileViewPage';
import UserClassicViewPage from './components/pages/UserClassicViewPage';
import UserViewPage from './components/pages/UserViewPage';
import './i18n';
import SubscriptionLayout from './layout/SubscriptionLayout';
import BrandPage from './modules/admin/brands/BrandPage';
import CreateBrands from './modules/admin/brands/CreateBrands';
import EditBrand from './modules/admin/brands/EditBrand';
import AdminDashboard from './modules/admin/dashboard/AdminDashboard';
import CreateQrCategories from './modules/admin/qrCategories/CreateQrCategories';
import CreateQrSubCategories from './modules/admin/qrCategories/CreateQrSubCategories';
import EditQrCategories from './modules/admin/qrCategories/EditQrCategories';
import EditSubQrCategories from './modules/admin/qrCategories/EditSubQrCategories';
import QrCategoriesList from './modules/admin/qrCategories/QrCategoriesList';
import QrSubCategories from './modules/admin/qrCategories/QrSubCategories';
import EditFeatures from './modules/admin/subscriptions/Features/EditFeatures';
import SubscriptionFeatures from './modules/admin/subscriptions/Features/SubscriptionFeatures';
import CreateUser from './modules/admin/users/CreateUser';
import EditUserPage from './modules/admin/users/EditUserPage';
import EditUserPermissions from './modules/admin/users/EditUserPermissions';
import LoginAsGest from './modules/authentication/LoginAsGest';
import CreateUserPage from './modules/business/cards/CreateUserPage';
import CVTemplate3 from './modules/business/cv/CVTemplate3';
import TemplateSelectionPage from './modules/business/cv/TemplateSelectionPage';
import ContactMapsPage from './modules/business/home/ContactMapsPage';
import QrCodeCreatePage from './modules/business/qrCodes/QrCodeCreatePage';
import QrCodePage from './modules/business/qrCodes/QrCodePage';
import GuestOrderPage from './modules/business/store/GuestOrderPage';
import GuestPreviewOrderPage from './modules/business/store/GuestPreviewOrderPage';
import MyOrderPage from './modules/business/store/MyOrderPage';
import MyPreviewOrderPage from './modules/business/store/MyPreviewOrderPage';
import OrderPage from './modules/business/store/OrderPage';
import PreviewOrderPage from './modules/business/store/PreviewOrderPage';
import ProductPreview from './modules/business/store/ProductPreview';
import BillingPage from './modules/subscription/BillingPage';
import FeaturesOfSingleSubscription from './modules/subscription/FeaturesOfSingleSubscription';
import PlansPage from './modules/subscription/PlansPage';
import SubscriptionHistoryPage from './modules/subscription/SubscriptionHistoryPage';
import { useGetMyProfileQuery } from './redux/features/auth/authApi';
import { useUserPointLocationMutation } from './redux/features/users/usersApi';
//import UserClassicViewPage from "./components/pages/UserClassicViewPage";

const App = () => {
  const [loading, setLoading] = useState(false);
  const { data } = useGetMyProfileQuery();
  // const [updateProfile] = useEditDashboardUserProfileMutation();
  const [updateProfileLocations] = useUserPointLocationMutation();

  //store Cart

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  const [quantities, setQuantities] = useState(() => {
    const savedQuantities = localStorage.getItem('quantities');
    return savedQuantities ? JSON.parse(savedQuantities) : {};
  });

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    localStorage.setItem('quantities', JSON.stringify(quantities));
  }, [cart, quantities]);

  const addToCart = (product) => {
    setCart((prevCart) => {
      const existingItem = prevCart.find((item) => item._id === product._id);

      if (existingItem) {
        return prevCart.map((item) =>
          item._id === product._id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevCart, { ...product, quantity: 1 }];
      }
    });
  };

  const removeFromCart = (product) => {
    setCart((prevCart) => {
      const exist = prevCart.find((item) => item._id === product._id);
      if (exist?.quantity === 1) {
        return prevCart.filter((item) => item._id !== product._id);
      } else {
        return prevCart.map((item) =>
          item._id === product._id
            ? { ...item, quantity: item.quantity - 1 }
            : item
        );
      }
    });

    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [product._id]: Math.max((prevQuantities[product._id] || 1) - 1, 0),
    }));
  };

  const deleteFromCart = (product) => {
    setCart((prevCart) => prevCart.filter((item) => item._id !== product._id));
    setQuantities((prevQuantities) => {
      const newQuantities = { ...prevQuantities };
      delete newQuantities[product._id];
      return newQuantities;
    });
  };

  const CVTemplateHandler = () => {
    const { id } = useParams();

    if (id === '1') {
      //return <CVTemplate />;
    } else if (id === '2') {
      //return <CVTemplate2 />;
    } else if (id === '3') {
      return <CVTemplate3 />;
    } else {
      return <div>Template not found</div>;
    }
  };

  const updateUserProfileLocation = async (payload) => {
    try {
      // await updateProfile(payload)
      updateProfileLocations(payload);
    } catch (error) {
      // console.log(error);
    }
  };
  // useEffect(() => {
  //   if (
  //     data?.success &&
  //     !data?.data?.location?.long &&
  //     !data?.data?.location?.lat
  //   ) {
  //     getCurrentLocation(
  //       function (location) {
  //         const currLocation = [location.longitude, location.latitude];

  //         // console.log('from app', currentLocation);
  //         // updateUserProfileLocation({ id: data?.data?._id, data: { location: currLocation } })
  //         updateUserProfileLocation({
  //           id: data?.data?._id,
  //           data: { coordinates: currLocation },
  //         });
  //       },
  //       function (error) {
  //         console.error('Error:', error);
  //       }
  //     );
  //   }
  // }, [data]);

  // posts, stores, cards, home, stores and store reviews,  products and product categories, settings, cars, interests, wifi qr, addresses,

  return (
    !loading && (
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />

        {/* common route  */}
        {/* home  */}
        <Route
          path="home"
          element={
            <PrivateRoute>
              <HomePage />
            </PrivateRoute>
          }
        />

        {/* card views  */}
        <Route
          path="/view/:id"
          element={
            // <PrivateRoute>
            <CardViewPage />

            // </PrivateRoute>
          }
        />

        <Route
          path="create/card"
          element={
            // <PrivateRoute>
            <CreateUserPage />

            // </PrivateRoute>
          }
        />
        <Route
          path="/user/view/:id"
          element={
            // <PrivateRoute>
            <UserViewPage />
            // </PrivateRoute>
          }
        />

        <Route
          path="/user/classic-view/:id"
          element={
            // <PrivateRoute>
            <UserClassicViewPage />
            // </PrivateRoute>
          }
        />

        <Route
          path="stores/public-view/:id"
          element={
            <StorePreviewPage
              addToCart={addToCart}
              cart={cart}
              removeFromCart={removeFromCart}
              isPublic={true}
            />
          }
        />
        <Route
          path="product/view/:productId"
          element={
            <ProductPreview
              addToCart={addToCart}
              cart={cart}
              quantities={quantities}
              removeFromCart={removeFromCart}
            />
          }
        />
        <Route
          path="/cart"
          element={
            <CartPage
              cartItems={cart}
              setCart={setCart}
              onAdd={addToCart}
              onRemove={removeFromCart}
              onDelete={deleteFromCart}
              storeData={data}
            />
          }
        />
        <Route path="/posts/view/:id" element={<HomePage />} />
        <Route path="/maps/contact" element={<ContactMapsPage />} />
        <Route path="/contacts/create" element={<AddNewContactPage />} />
        <Route path="/contacts/edit/:id" element={<EditContactPage />} />
        <Route
          path="cars/public-view/:id"
          element={<CarCardViewPage isPublic={true} />}
        />
        <Route path="/orders" element={<GuestOrderPage />} />
        <Route path="my-order/:id" element={<GuestPreviewOrderPage />} />

        {/* <Route path="cars/public-view/:id" element={<CarCardViewPage />} /> */}
        {/* <Route
          path="cars/view/:id"
          element={
            // <PrivateRoute>
            <CarCardViewPage />
            // </PrivateRoute>
          }
        /> */}

        {/* common  */}
        <Route
          path="/"
          element={
            <PrivateRoute>
              <CommonLayout />
            </PrivateRoute>
          }
        >
          {/* profile updated  */}
          <Route path="users/edit/:id" element={<EditUserPage />} />

          {/* posts */}
          <Route path="posts" element={<PostsPage />} />
          <Route path="posts/edit/:id" element={<EditPost />} />

          {/* stores and store reviews */}
          <Route path="stores" element={<MyStorePage />} />
          <Route path="stores/create" element={<CreateStorePage />} />
          <Route path="stores/edit/:id" element={<EditStorePage />} />
          <Route
            path="stores/view/:id"
            element={
              <StorePreviewPage
                isPublic={false}
                addToCart={addToCart}
                cart={cart}
                removeFromCart={removeFromCart}
              />
            }
          />
          <Route
            path="/cart"
            element={
              <CartPage
                cartItems={cart}
                onAdd={addToCart}
                onRemove={removeFromCart}
                onDelete={deleteFromCart}
              />
            }
          />

          <Route path="store-reviews" element={<StoreReviewPage />} />
          <Route path="orders-list" element={<OrderPage />} />
          <Route path="order/:id" element={<PreviewOrderPage />} />

          <Route path="my-orders-list" element={<MyOrderPage />} />
          <Route path="my-product/:id" element={<MyPreviewOrderPage />} />

          {/* products and product categories  */}
          <Route path="products" element={<ProductsPage />} />
          <Route path="products/create" element={<CreateProductPage />} />
          <Route path="products/edit/:id" element={<EditProductPage />} />
          <Route
            path="product-categories"
            element={<ProductCategoriesPage />}
          />
          <Route
            path="product-categories/create"
            element={<CreateCategoriesPage />}
          />
          <Route
            path="product-categories/edit/:id"
            element={<EditCategoriesPage />}
          />

          {/* friends categorio */}
          <Route
            path="product-categories/create"
            element={<ProductCategoriesPage />}
          />
          <Route
            path="product-categories/create"
            element={<CreateCategoriesPage />}
          />
          <Route
            path="product-categories/edit/:id"
            element={<EditCategoriesPage />}
          />

          {/* qr codes */}
          <Route path="/qr-codes" element={<QrCodePage />} />
          <Route path="/qr-codes/create" element={<QrCodeCreatePage />} />

          {/* qr categories  */}
          <Route path="/qr-categories" element={<QrCategoriesList />} />
          <Route
            path="/qr-categories/create"
            element={<CreateQrCategories />}
          />
          <Route
            path="/qr-categories/edit/:id"
            element={<EditQrCategories />}
          />

          <Route path="/qr-sub-categories" element={<QrSubCategories />} />
          <Route
            path="/qr-sub-categories/create"
            element={<CreateQrSubCategories />}
          />
          <Route
            path="/qr-sub-categories/edit/:id"
            element={<EditSubQrCategories />}
          />

          {/* cards route  */}
          <Route path="cards" element={<CardsPage />} />
          <Route path="cards/create" element={<CreateCardPage />} />
          <Route path="analytics/:id" element={<AnalyticsPage />} />
          <Route path="card/edit/:id" element={<EditCardPage />} />

          {/* addresses  */}
          <Route path="addresses" element={<AddressPage />} />
          <Route path="addresses/edit/:id" element={<EditAddressPage />} />
          <Route path="addresses/create" element={<CreateAddressPage />} />

          {/* wifi qr  */}
          <Route path="wifi-qr" element={<WifiQrPage />} />
          <Route path="wifi-qr/create" element={<WifiQrCreate />} />

          {/* interests  */}
          {/* <Route path="interests" element={<InterestsPage />} /> */}
          <Route path="messages" element={<InterestsPage />} />

          {/* cars
          <Route path="cars" element={<CarsPage />} />*/}
          <Route path="all/cars" element={<AllCarsPage />} />
          <Route path="cars" element={<CreateCarsPage />} />
          <Route path="cars/edit/:id" element={<CreateCarsPage />} />
          <Route
            path="cars/view/:id"
            element={<CarCardViewPage isPublic={false} />}
          />
          {/* friends  */}
          <Route path="friends" element={<FriendsPage />} />
          <Route path="friends/all/:id" element={<SeeAllFriendsPage />} />

          {/* friends  */}
          <Route
            path="social/profile/:id"
            element={<SocialProfileViewPage />}
          />
          <Route
            path="social/profile/notifications/birthday"
            element={<BirthdayNotificationsPage />}
          />
          <Route
            path="social/profile/nearby"
            element={<NearbyLocationDiscounts />}
          />

          <Route path="friends/categories" element={<FriendCategoriesList />} />
          <Route
            path="friends/categories/create"
            element={<CreateFriendCategories />}
          />
          <Route
            path="friends/categories/edit/:id"
            element={<EditFriendsCategories />}
          />

          {/* settings  */}
          <Route path="settings" element={<SettingsPage />} />

          <Route path="/cv" element={<TemplateSelectionPage />} />
          <Route path="/cv/:id" element={<CVTemplateHandler />} />
          <Route
            path="/subscription/features"
            element={<SubscriptionLayout />}
          />
          <Route
            path="/subscription/history"
            element={<SubscriptionHistoryPage />}
          />
          <Route
            path="/subscription/plans/:featureId"
            element={<PlansPage />}
          />
          <Route
            path="/subscription-features-by-id/:id"
            element={<FeaturesOfSingleSubscription />}
          />
          <Route path="/plans/billing" element={<BillingPage />} />
        </Route>

        {/* admin route  */}
        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="qr-categories" element={<QrCategoriesList />} />
          <Route path="qr-categories/create" element={<CreateQrCategories />} />
          <Route path="qr-categories/edit/:id" element={<EditQrCategories />} />
          <Route path="qr-sub-categories" element={<QrSubCategories />} />
          <Route
            path="qr-sub-categories/create"
            element={<CreateQrSubCategories />}
          />
          <Route
            path="qr-sub-categories/edit/:id"
            element={<EditSubQrCategories />}
          />
          <Route path="brands" element={<BrandPage />} />
          <Route path="brands/create" element={<CreateBrands />} />
          <Route path="brands/edit/:id" element={<EditBrand />} />
          <Route path="users" element={<UsersPage />} />
          <Route path="create-user" element={<CreateUser />} />
          <Route
            path="user/permissions/:id"
            element={<EditUserPermissions />}
          />
          <Route path="subscriptions-plan" element={<SubscriptionsPage />} />
          <Route
            path="subscriptions/create"
            element={<CreateSubscriptionPage />}
          />
          <Route
            path="subscriptions/edit/:id"
            element={<EditSubscriptionPage />}
          />
          FeaturesOfSingleSubscription
          {/* Features */}
          <Route
            path="subscription-features"
            element={<SubscriptionFeatures />}
          />
          <Route
            path="subscription-features/edit/:id"
            element={<EditFeatures />}
          />
        </Route>

        {/* agent route  */}
        <Route
          path="/agent"
          element={
            <PrivateRoute>
              <AgentLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="hot-map" element={<HotSection />} />
          <Route path="all-clients" element={<ClientsAll />} />
          <Route path="edit-client/:id" element={<EditClient />} />
          <Route path="demo-accounts" element={<DemoAccounts />} />
          <Route path="create-user" element={<CreateNewClient />} />
          <Route path="order-reports" element={<OrdersReport />} />
          <Route path="qr-links" element={<QRPlayground />} />
          <Route path="qr-create" element={<CreateQR />} />
          <Route path="web-messages" element={<WebMessages />} />
          {/* <Route path="categories" element={<Categories />} /> */}
        </Route>

        {/* business route  */}
        <>
          <Route
            path="/business/statics"
            element={
              <PrivateRoute>
                <StaticsPage />
              </PrivateRoute>
            }
          />

          <Route
            path="/employee/view/:id"
            element={
              // <PrivateRoute>
              <EmployeeCardViewPage />
              // </PrivateRoute>
            }
          />
          <Route
            path="/team/view/:id"
            element={
              // <PrivateRoute>
              <TeamCardViewPage />
              // </PrivateRoute>
            }
          />

          <Route
            path="/business/all-cards"
            element={
              <PrivateRoute>
                <TeamCardsPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/business/all-cards/create"
            element={<CreateTeamCardPage />}
          />

          {/* employees  */}
          <Route path="/cards/employees" element={<EmployeesPage />} />
          <Route
            path="/cards/employees/create"
            element={<EmployeeCreatePage />}
          />
          <Route
            path="/cards/employees/edit/:id"
            element={<EmployeeEditPage />}
          />
        </>

        {/* store route  */}
        <Route
          path="/store"
          element={
            <PrivateRoute>
              <StoreLayout />
            </PrivateRoute>
          }
        >
          {/* <Route path="/store/home" element={<StoreHome />} /> */}
          <Route path="home" element={<StoreHome />} />
          <Route path="statics" element={<StoreStatics />} />
          <Route path="all-cards" element={<StoreCards />} />
          <Route path="profile-question" element={<StoreProfileQuestion />} />
          <Route path="create-card" element={<StoreCreateCard />} />
          <Route path="view-card" element={<StoreCardView />} />
          <Route path="edit-card" element={<StoreEditCard />} />
          <Route path="cars" element={<StoreCars />} />
          <Route path="posts" element={<StorePosts />} />
          <Route path="wifi-qr" element={<StoreWifiQr />} />
          <Route path="wifi-qr-create" element={<CreateWifiQr />} />
          <Route path="wifi-qr/index" element={<StoreWifiQr />} />
          <Route path="my-store" element={<StoreMyStore />} />
          <Route
            path="create-categories"
            element={<StoreCreateProductCategories />}
          />
          <Route path="edit-categories" element={<StoreEditCategoriesPage />} />
          <Route
            path="product-categories"
            element={<StoreProductCategories />}
          />
          <Route path="products" element={<StoreProducts />} />
          <Route path="reviews" element={<StoreStoreReviews />} />
          <Route path="create-store" element={<StoreStoreCreate />} />
          <Route path="update-store" element={<StoreStoreUpdate />} />
          <Route path="addresses" element={<StoreAddresses />} />
          <Route path="create-addresses" element={<StoreCreateAddresses />} />
          <Route path="edit-addresses" element={<StoreEditAddresses />} />
          <Route path="settings" element={<StoreSettings />} />
          <Route path="interests" element={<StoreInterests />} />
        </Route>

        {/* individual route  */}
        <Route
          path="/individual"
          element={
            <PrivateRoute>
              <IndividualLayout />
            </PrivateRoute>
          }
        >
          {/* <Route path="/individual/home" element={<IndividualHome />} /> */}
          <Route path="home" element={<IndividualHome />} />
          <Route path="statics" element={<IndividualStatics />} />
          <Route path="all-cards" element={<IndividualCards />} />
          <Route path="cars" element={<IndividualCars />} />
          <Route path="card/edit/:id" element={<IndividualEditCard />} />
          <Route path="card/view/:id" element={<IndividualViewCardPage />} />
          <Route path="posts" element={<IndividualPosts />} />
          <Route path="wifi-qr" element={<IndividualWifiQr />} />
          <Route path="wifi-qr/create" element={<IndividualWifiQrCreate />} />
          <Route path="wifi-qr/index" element={<IndividualWifiQr />} />
          <Route path="my-store" element={<IndividualMyStore />} />
          <Route
            path="create-categories"
            element={<IndividualCreateProductCategories />}
          />
          <Route
            path="edit-categories"
            element={<IndividualEditCategoriesPage />}
          />
          <Route
            path="product-categories"
            element={<IndividualProductCategories />}
          />
          <Route path="products" element={<IndividualProducts />} />
          <Route path="reviews" element={<IndividualStoreReviews />} />
          <Route path="create-store" element={<IndividualStoreCreate />} />
          <Route path="update-store" element={<IndividualStoreUpdate />} />
          <Route path="addresses" element={<IndividualAddresses />} />
          <Route
            path="create-addresses"
            element={<IndividualCreateAddresses />}
          />
          <Route path="edit-addresses" element={<IndividualEditAddresses />} />
          <Route path="settings" element={<IndividualSettings />} />
          <Route path="interests" element={<IndividualInterests />} />
        </Route>

        {/* login route  */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/password/reset" element={<ForgetPasswordPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/login-as-guest" element={<LoginAsGest />} />

        <Route
          path="*"
          element={
            <PrivateRoute>
              <DefaultLayout>Welcome</DefaultLayout>
            </PrivateRoute>
          }
        />
      </Routes>
    )
  );
};

export default App;
