import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import Loading from '../../../components/Loading';
import {
  useGetUserQuery,
  useUpdateUserViewModeMutation,
} from '../../../redux/features/users/usersApi';
import ClassicView from '../../business/cards/ClassicView';
import ModernView from '../../business/cards/modernView/ModernView';

const EditUserPage = () => {
  const { id } = useParams();
  // const { data, isLoading, refetch } = useGetUserQuery(id);
  const [searchParams] = useSearchParams();
  // const { data, isLoading } = useGetCardQuery(id);
  const paramsUserId = searchParams.get('userId');
  const paramsIsChildBranch = searchParams.get('isChildBranch');
  const { data, isLoading, refetch } = useGetUserQuery({
    id: paramsUserId,
    branchId: id,
    isChildBranch: paramsIsChildBranch,
  });
  const [viewMode, setViewMode] = useState(data?.data?.userMode || 'classic');
  const { pathname } = useLocation();

  const formatData = {
    data: {
      user: data?.data,
    },
  };

  const [updateUserViewMode] = useUpdateUserViewModeMutation();

  const handelChangeViewMode = (mode) => {
    setViewMode(mode);
    updateUserViewMode(mode);
  };

  useEffect(() => {
    if (data?.data?.userMode) {
      setViewMode(data?.data?.userMode || 'classic');
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [pathname]);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full items-center justify-center">
          <Loading height="50vh" />
        </div>
      ) : (
        <div className="space-y-6">
          <div className="mx-auto flex w-[160px] items-center justify-between gap-3 rounded-md bg-white">
            <button
              onClick={() => handelChangeViewMode('classic')}
              className={`${
                viewMode === 'classic'
                  ? '!bg-green-500 !text-white'
                  : '!bg-transparent !text-black'
              } flex w-[80px] justify-center rounded-l-lg px-3 py-2 text-[14px] `}
            >
              Classic
            </button>
            <button
              onClick={() => handelChangeViewMode('modern')}
              className={`${
                viewMode === 'modern'
                  ? '!bg-green-500 !text-white'
                  : '!bg-transparent !text-black'
              } flex w-[80px] justify-center rounded-r-lg px-3 py-2 text-[14px]`}
            >
              Modern
            </button>
          </div>

          {isLoading ? (
            <div className="flex w-full items-center justify-center">
              <Loading height="50vh" />
            </div>
          ) : (
            <>
              {viewMode === 'modern' ? (
                <ModernView card={formatData?.data} />
              ) : (
                <ClassicView
                  card={data}
                  variant="user"
                  paramsUserId={paramsUserId}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default EditUserPage;
