import { format } from 'date-fns';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { capitalizeFirstLetter } from '../../utils/dataFormater';

const SubscriptionActions = ({ currentPlanData }) => {
  const navigate = useNavigate();
  const handleViewFeaturesClick = () => {
    navigate('/subscription/features');
  };
  const handleUpgradePlans = () => {
    navigate('/subscription/plans');
  };
  return (
    <div className="flex w-1/2 flex-col items-center justify-center rounded-md bg-gray-100 p-6 text-center">
      <p className="mb-1 text-gray-800">Your Plan</p>
      <p className="mb-2 font-semibold text-gray-800">
        {capitalizeFirstLetter(currentPlanData?.planName) === 'Undefined'
          ? ''
          : capitalizeFirstLetter(currentPlanData?.planName)}
      </p>
      {/* {JSON.stringify(currentPlanData)} */}
      {currentPlanData?.expiryDate && (
        <p className="mb-2 text-gray-800">
          Expire on:{' '}
          {currentPlanData?.expiryDate
            ? format(new Date(currentPlanData.expiryDate), 'MMMM dd, yyyy')
            : ''}
        </p>
      )}

      <div className="flex justify-center space-x-4">
        <button
          className="rounded-md bg-blue-500 px-4 py-2 text-white"
          onClick={handleViewFeaturesClick}
        >
          View Plans
        </button>
        {/* <button
          className="rounded-md bg-gray-300 px-4 py-2 text-gray-800"
          onClick={handleUpgradePlans}
        >
          Upgrade Subscription
        </button>
        <button className="rounded-md bg-red-500 px-4 py-2 text-white">
          Cancel Subscription
        </button> */}
      </div>
    </div>
  );
};

export default SubscriptionActions;
