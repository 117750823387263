import moment from 'moment';
import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import toast from 'react-hot-toast';
import { FaEye, FaPenToSquare } from 'react-icons/fa6';
import { MdDelete } from 'react-icons/md';
import Loading from '../../../components/Loading';
import Table from '../../../components/Table';
import Button from '../../../components/ui/Button';
import Card from '../../../components/ui/Card';
import { useDeleteContactMutation } from '../../../redux/features/contacts/contactsApi';

const LatestContacts = ({ data, isLoading, refetch }) => {
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 10,
  });

  // const { data, isLoading, refetch } = useGetContactsQuery(paginate);
  // useEffect(() => {
  //   refetch();
  // }, [categoryId]);
  const [deleteContact] = useDeleteContactMutation();

  const handleDeleteApi = async (id) => {
    try {
      const result = await deleteContact(id);
      if (result?.data?.success) {
        toast.success(result?.data?.message);
        refetch();
      }
      if (result?.error?.status === 400) {
        toast.error(result?.error?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: 'Confirm to Delete',
      message: 'Are you sure you want to delete this contact?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => handleDeleteApi(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  const content = {
    header: [
      'Contact Image',
      'Contact Name',
      'Contact Mobile Number',
      // "Est Distance",
      'Added At',
      'Actions',
    ],

    body: data?.data?.results?.map((item) => ({
      rows: [
        {
          row: item?.photoUrl ? (
            <div className="h-[40px] w-[40px] border">
              <img src={item?.photoUrl} alt="" />
            </div>
          ) : (
            'N/A'
          ),
        },
        {
          row: item?.contactInfo?.name,
        },
        {
          row: (
            <span>
              {item?.contactInfo?.phone?.dialCode}
              {item?.contactInfo?.phone?.fullNumber}
            </span>
          ),
        },
        // {
        //   row: "N/A",
        // },
        {
          row: moment(item?.createdAt)?.fromNow(),
        },
        {
          row: (
            <div className="flex items-center gap-3">
              {/* <button className="rounded-md bg-blue-100 px-3 py-1 font-medium text-white">
                Edit
              </button> */}
              <Button
                link={`/contacts/edit/${item?._id}`}
                className="!block !bg-green-400"
              >
                <FaPenToSquare />
              </Button>
              <Button
                onClick={() => handleDelete(item?._id)}
                className="!bg-danger-100 !text-white"
              >
                <MdDelete />
              </Button>
              <Button
                link={`/user/view/${item?.cardData?._id}?country-code=${item?.location?.country}&&type=${item?.role}&&username=${item?.username}&&isChildBranch=${item?.cardData?.isChildBranch}&&userId=${item?.cardData?.user}`}
                className="flex h-[30px] w-[50px] items-center justify-center  !bg-blue-100 "
              >
                <FaEye />
              </Button>
            </div>
          ),
        },
      ],
    })),
  };

  return (
    <Card
      title="Latest "
      content={
        <span className="rounded-md bg-danger-100 px-2 py-1 text-[10px] font-medium text-white">
          {data?.data?.paginate?.totalCount} New Member
        </span>
      }
    >
      <div className="p-5">
        {isLoading ? (
          <Loading height="20vh" />
        ) : (
          <Table
            content={content}
            pagination={data?.data?.paginate}
            onPaginate={setPaginate}
          />
        )}
      </div>
    </Card>
  );
};

export default LatestContacts;
